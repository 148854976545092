<template>
  <div class="content commonText">
    <van-field v-model="inputCode" center placeholder="请输入兑换码">
      <template #button>
        <van-button
          size="small"
          type="primary"
          :color="common.mainColor"
          @click="toExchange"
          >兑换红包</van-button
        >
      </template>
    </van-field>

    <div class="centerDiv" style="margin-bottom: 2rem">
      <div class="mainColor maTo20">请输入团购券码</div>
      <div class="maTo20">
        <div v-if="couponList.length > 0" style="margin-bottom: 1rem">
          <div
            class="couponDiv maTo30 pflex"
            v-for="(item, index) in couponList"
            :key="index"
          >
            <div class="cflex mainColor" style="width: 30%">
              <div>
                <span>¥</span>
                <span style="font-size: 0.45rem; margin-left: 0.15rem">{{
                  item.amount
                }}</span>
              </div>
              <div class="maTo20" style="font-size: 0.23rem">
                <span v-if="item.limitAmount > 0"
                  >满{{ item.limitAmount }}可用</span
                >
                <span v-else>无门槛红包</span>
              </div>
            </div>
            <div style="width: 70%">
              <div>{{ item.name }}</div>
              <div class="miniText maTo20">有效期至{{ item.endTime }}</div>
            </div>
            <!-- <div
              style="width: 20%; font-size: 0.23rem"
              class="useButton mainColor pflex"
              @click="toPage('index')"
            >
              立即使用
            </div> -->
          </div>
        </div>
      </div>
    </div>

    <div class="bottomButton">
      <div
        class="centerDiv pflex mainBgc toOrderButton"
        style="width: 85%"
        @click="toPage('index')"
      >
        立即使用
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      inputCode: "",
      couponList: [],
    };
  },
  mounted() {
    this.getCouponList();
  },
  methods: {
    getCouponList() {
      const that = this;
      that.http
        .get("coupon/groupList.json", {
          token: that.$store.state.token,
        })
        .then((res) => {
          if (res.data.memberCouponVOList) {
            that.couponList = res.data.memberCouponVOList;
          }
        });
    },
    toExchange() {
      const that = this;
      if (that.inputCode.length > 0) {
        that.http
          .get("coupon/verification.json", {
            token: that.$store.state.token,
            authCode: that.inputCode,
          })
          .then((res) => {
            if (res.data.stateVO.code == 200) {
              that.$toast("成功");
              that.getCouponList();
            }
          });
      } else {
        that.$toast("请输入兑换码");
      }
    },
  },
};
</script>

<style scoped>
.couponDiv {
  width: 100%;
  background: white;
  padding: 0.4rem 0;
  box-shadow: 0px 0.05rem 0.13rem #d8d8d8;
}

.bottomButton {
  width: 100%;
  position: fixed;
  left: 0;
  bottom: 0;
  padding-bottom: 0.4rem;
}

.toOrderButton {
  color: #ffffff;
  padding: 0.24rem;
  border-radius: 0.15rem;
}
</style>