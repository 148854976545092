<template>
  <div class="content commonText">
    <div class="centerDiv" style="padding-top: 0.2rem; padding-bottom: 3rem">
      <div
        class="maTo20 pflex bt"
        v-for="(item, index) in couponList"
        :key="index"
        @click="chooseCoupon(index, item)"
      >
        <div class="chooseradio">
          <div v-if="item.choose == 1" class="choose mainBgc"></div>
        </div>
        <div class="couponDiv pflex">
          <div
            class="cflex"
            style="width: 30%"
            :class="item.isReuse == 1 ? 'mainColor' : 'orange'"
          >
            <div>
              <span>¥</span>
              <span style="font-size: 0.45rem; margin-left: 0.15rem">{{
                item.amount
              }}</span>
            </div>
            <div class="maTo20" style="font-size: 0.23rem">
              <span v-if="item.limitAmount > 0"
                >满{{ item.limitAmount }}可用</span
              >
              <span v-else>无门槛红包</span>
            </div>
          </div>
          <div style="width: 50%">
            <div>{{ item.name }}</div>
            <div class="miniText maTo20">有效期至{{ item.endTime }}</div>
          </div>
          <div
            style="width: 20%; font-size: 0.23rem"
            class="useButton mainColor pflex"
            v-if="item.isReuse == 1"
          >
            同享券
          </div>

          <div
            style="width: 20%; font-size: 0.23rem"
            class="useButton pflex orange"
            v-else
          >
            互斥券
          </div>
        </div>
      </div>
    </div>

    <!-- bottomButtonGroup -->
    <div class="bottomButtonGroup">
      <div class="centerDiv pflex bt">
        <div class="left couponButton pflex" @click="clearCouponList">
          不使用红包
        </div>
        <div class="right couponButton pflex mainBgc" @click="back">
          自动搭配
        </div>
        <div class="right couponButton pflex mainBgc" @click="back">
          确定使用
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      couponList: [],
      useCouponList: [],
      totalAmount: 0,
    };
  },
  mounted() {
    const that = this;
    that.useCouponList = that.$store.state.useCouponList;
    if (that.$route.params.totalAmount) {
      that.totalAmount = that.$route.params.totalAmount;
      that.http
        .get("coupon/useList.json", {
          token: that.$store.state.token,
          state: 1,
        })
        .then((res) => {
          that.couponList = res.data.memberCouponVOList;
          for (let i of that.couponList) {
            i.choose = 0;
          }
        });
    } else {
      that.toPage("order");
    }
  },
  methods: {
    chooseCoupon(index, item) {
      const that = this;
      if (item.limitAmount > that.totalAmount) {
        that.$dialog.alert({
          title: "提示",
          message: "订单金额未达到满减门槛",
          confirmButtonColor: that.common.mainColor,
        });
      } else {
        // 选择叠加券
        if (item.isReuse == 1) {
          for (let i in that.couponList) {
            // 取消所有的互斥券
            if (that.couponList[i].isReuse == 0) {
              that.couponList[i].choose = 0;
            } else {
              if (i == index) {
                // 如果是之前选过的叠加券，取消选择
                if (that.couponList[i].choose == 1) {
                  that.couponList[index].choose = 0;
                } else {
                  // 如果未选择，就添加选择
                  that.couponList[index].choose = 1;
                }
              }
            }
          }
        } else {
          // 非叠加券的情况
          for (let i in that.couponList) {
            if (that.couponList[i].id == item.id) {
              that.couponList[i].choose = 1;
            } else {
              that.couponList[i].choose = 0;
            }
          }
        }
        that.$forceUpdate();
      }
    },
    confirm() {
      const that = this;
      let useCouponListUpdate = [];
      for (let i of that.couponList) {
        if (i.choose == 1) {
          useCouponListUpdate.push(i);
        }
      }
    },
    clearCouponList() {
      this.$store.commit("useCouponListUpdate", []);
      this.toPage("order");
    },
  },
};
</script>

<style scoped>
.couponDiv {
  width: 90%;
  background: white;
  padding: 0.4rem 0;
  /* border:#e9e9e9 solid 0.01rem */
  box-shadow: 0px 0.05rem 0.13rem #d8d8d8;
}

.chooseradio {
  width: 0.4rem;
  height: 0.4rem;
  border-radius: 50%;
  border: #1aad19 solid 1px;
  display: flex;
  justify-content: center;
  align-items: center;
  /* margin-right: 0.15rem; */
}

.choose {
  width: 0.15rem;
  height: 0.15rem;
  border-radius: 100%;
}

.bottomButtonGroup {
  position: fixed;
  width: 100%;
  bottom: 0;
  left: 0;
  padding: 0.3rem 0;
  background: white;
}

.couponButton {
  width: 32%;
  padding: 0.3rem 0;
  border-radius: 0.15rem;
}

.right {
  color: #ffffff;
}

.left {
  /* border: #f0e9e9 solid 1px; */
  background: #eee9e9;
}
</style>