<template>
  <div class="content commonText" style="background: white">
    <div class="centerDiv pflex bt miniText choose" @click="openTimeDrawer">
      <div class="pflex">
        <van-icon name="todo-list-o" />
        <span style="margin-left: 0.1rem" v-if="showTime.length == 0"
          >请选择上门取件的时间</span
        >
        <span style="margin-left: 0.1rem" v-else>{{ showTime }}</span>
      </div>
      <div><van-icon name="arrow" /></div>
    </div>
    <van-popup v-model="show" position="bottom" :style="{ height: '50%' }">
      <div class="timeHead">
        <div class="centerDiv">请选择预约时间</div>
      </div>
      <van-tabs
        v-model="indexDay"
        :title-active-color="common.mainColor"
        :color="common.mainColor"
        @change="changeTab"
      >
        <van-tab v-for="(day, num) in tab" :key="num" :title="day.day">
          <!-- <div class="centerDiv pflex bt" style="flex-wrap: wrap">
            <div
              v-for="(item, index) in time"
              :key="index"
              class="timeDiv maTo30 pflex"
            >
              {{ item.date }}
            </div>
          </div> -->
        </van-tab>
      </van-tabs>
      <van-swipe
        class="swipe"
        indicator-color="white"
        :loop="false"
        :show-indicators="false"
        @change="swiperChange"
        ref="swipe"
      >
        <van-swipe-item v-for="(day, num) in tab" :key="num">
          <div class="centerDiv pflex bt" style="flex-wrap: wrap">
            <div
              class="timeDiv maTo30 pflex"
              v-for="item in cantChooseCom"
              :key="item + 100"
              style="background: #e4e4e4"
            >
              已约满
            </div>
            <div
              v-for="(item, index) in canChooseCom"
              :key="index"
              class="timeDiv maTo30 pflex"
              @click="chooseTime(item.date)"
            >
              {{ item.date }}
            </div>
          </div>
        </van-swipe-item>
      </van-swipe>
    </van-popup>
  </div>
</template>

<script>
export default {
  data() {
    return {
      show: false,
      indexDay: 0,
      hour: 10, // 当前时间（时）
      cantChoose: 0, // 无法选择个数
      showTime: "",
      tab: [
        {
          day: "今天",
          date: this.nowDate,
          index: 0,
        },
        {
          day: "明天",
          date: this.torDate,
          index: 1,
        },
        {
          day: "后天",
          date: this.aftDate,
          index: 2,
        },
      ],
      // 时间数组
      time: [
        {
          date: "9:00~11:00",
          id: "t1",
        },
        {
          date: "10:00~12:00",
          id: "t2",
        },
        {
          date: "11:00~13:00",
          id: "t3",
        },
        {
          date: "12:00~14:00",
          id: "t4",
        },
        {
          date: "13:00~15:00",
          id: "t5",
        },
        {
          date: "14:00~16:00",
          id: "t6",
        },
        {
          date: "15:00~17:00",
          id: "t7",
        },
        {
          date: "16:00~18:00",
          id: "t8",
        },
        {
          date: "17:00~19:00",
          id: "t9",
        },
      ],
      canChoose: [],
    };
  },
  mounted() {
    const that = this;
    let myDate = new Date();
    that.hour = myDate.getHours();
    if (that.hour <= 9) {
      that.canChoose = that.time;
      that.cantChoose = 0;
    } else {
      that.canChoose = that.time.slice(that.hour - 9);
      that.cantChoose =
        that.time.length - that.time.slice(that.hour - 9).length;
    }
  },
  computed: {
    cantChooseCom() {
      if (this.indexDay == 0) {
        return this.cantChoose;
      } else {
        return 0;
      }
    },
    canChooseCom() {
      if (this.indexDay == 0) {
        return this.canChoose;
      } else {
        return this.time;
      }
    },
  },
  methods: {
    reset() {
      console.log('1')
      this.showTime = "";
    },
    openTimeDrawer() {
      this.show = !this.show;
    },
    swiperChange(e) {
      this.indexDay = e;
    },
    changeTab(e) {
      this.$refs.swipe.swipeTo(e);
    },
    chooseTime(time) {
      const that = this;
      that.show = false;
      that.$emit("getChooseTime", {
        day: that.tab[that.indexDay].day,
        time: time,
      });
      that.showTime = that.tab[that.indexDay].day + "," + time;
    },
  },
};
</script>

<style scoped>
.choose {
  padding: 0.35rem 0;
  width: 95%;
  color: #414141;
  border-top: solid #e4e4e4 1px;
  border-bottom: solid #e4e4e4 1px;
}

.timeHead {
  padding: 0.27rem 0;
  border-bottom: solid #e4e4e4 1px;
}

.timeDiv {
  padding: 0.15rem 0;
  width: 30%;
  background: white;
  border: solid #e4e4e4 1px;
  border-radius: 0.1rem;
}
</style>