import router from "@/router.js"
import {
    Toast
} from 'vant';
router.beforeEach((to, from, next) => {
    let flag = localStorage.getItem('userInfo')
    if (to.meta.requireAuth == true) {
        if (!flag || flag == "undefined" || flag == undefined) {
            // next({
            //         path: '/login'
            //     })
            Toast("登录失败，请尝试重新进入或联系技术人员");
            // return next();
        } else {
            flag = JSON.parse(flag)
            if (flag.token) {
                return next();
            } else {
                // next({
                //         path: '/login'
                //     })
                Toast("登录失败，请尝试重新进入或联系技术人员");
                // return next();
            }
        }
    } else {
        return next();
    }
})

export default router;