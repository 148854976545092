<template>
  <div class="content commonText" style="background: #ffffff">
    <div v-if="addressList.length > 0">
      <!-- take -->
      <div
        class="addDiv"
        @click="
          toPageQuery('address', {
            choose: true,
            type: 'take',
          })
        "
      >
        <div class="centerDiv pflex">
          <div class="left pflex">
            <img src="@/images/addr.png" style="width: 100%" alt="" />
          </div>
          <div class="middle">
            <div class="centerDiv">
              <div style="line-height: 1.5em">
                {{ $store.state.chooseTakeAddress.province }}
                {{ $store.state.chooseTakeAddress.city
                }}{{ $store.state.chooseTakeAddress.district
                }}{{ $store.state.chooseTakeAddress.building
                }}{{ $store.state.chooseTakeAddress.address }}
              </div>
              <div class="maTo20 miniText">
                {{ $store.state.chooseTakeAddress.name }}
                {{ $store.state.chooseTakeAddress.telphone }}
              </div>
            </div>
          </div>
          <div class="right pflex">
            <van-icon name="arrow" />
          </div>
        </div>
      </div>

      <!-- line -->
      <div v-if="!isSame" class="line centerDiv"></div>

      <!-- send -->
      <div
        class="addDiv"
        v-if="!isSame"
        @click="
          toPageQuery('address', {
            choose: true,
            type: 'send',
          })
        "
      >
        <div class="centerDiv pflex">
          <div class="left pflex">
            <img src="@/images/addr-song.png" style="width: 100%" alt="" />
          </div>
          <div class="middle">
            <div class="centerDiv">
              <div style="line-height: 1.5em">
                {{ $store.state.chooseSendAddress.province }}
                {{ $store.state.chooseSendAddress.city
                }}{{ $store.state.chooseSendAddress.district
                }}{{ $store.state.chooseSendAddress.building
                }}{{ $store.state.chooseSendAddress.address }}
              </div>
              <div class="maTo20 miniText">
                {{ $store.state.chooseSendAddress.name }}
                {{ $store.state.chooseSendAddress.telphone }}
              </div>
            </div>
          </div>
          <div class="right pflex">
            <van-icon name="arrow" />
          </div>
        </div>
      </div>
    </div>

    <div
      v-else
      class="miniText pflex"
      style="padding: 0.5rem 0"
      @click="toPage('address', { choose: true, type: 'take' })"
    >
      点击创建地址
    </div>

    <!-- line -->
    <div class="addBottomLine"></div>

    <div>
      <div
        class="centerDiv pflex bt miniText"
        style="padding: 0.3rem 0; width: 95%; color: #414141"
        @click="changeSame"
      >
        <div>默认上门取件地址和为您送回的地址相同</div>
        <div
          style="font-size: 0.2rem"
          class="pflex"
          :class="isSame ? 'same mainBgc' : 'noSame'"
        >
          ✓
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      isSame: this.$store.state.addressSame,
    };
  },
  methods: {
    toPageQuery(page, params) {
      this.$router.push({ name: page, query: params });
    },
    changeSame() {
      this.isSame = !this.isSame;
      this.$store.commit("addressSameUpdate", this.isSame);
      if (this.isSame) {
        this.$store.commit("addressUpdate", {
          chooseSendAddress: this.$store.state.chooseTakeAddress,
        });
      }
    },
  },
  mounted() {
    // console.log(
    //   this.$store.state.chooseTakeAddress
    // )
    // const that = this;
    // that.$store.commit("addressUpdate", {
    //   chooseTakeAddress: that.addressList[0],
    // });
    // that.$store.commit("addressUpdate", {
    //   chooseSendAddress: that.addressList[0],
    // });
    // console.log(that.$store.state.chooseTakeAddress.city == undefined);
    // if (that.$store.state.chooseTakeAddress.city) {
    //   that.chooseTakeAddress = that.$store.state.chooseTakeAddress;
    // }
    // if (that.$store.state.chooseSendAddress.city) {
    //   that.chooseSendAddress = that.$store.state.chooseSendAddress;
    // }
    // console.log(this.$store.state.chooseSendAddress.province);
  },
  props: {
    addressList: Array,
  },
  watch: {
    addressList(val) {
      const that = this;
      if (
        that.$store.state.chooseTakeAddress.city == undefined ||
        !that.$store.state.chooseTakeAddress.city
      ) {
        that.$store.commit("addressUpdate", {
          chooseTakeAddress: val[0],
        });
        that.$store.commit("addressUpdate", {
          chooseSendAddress: val[0],
        });
      } else {
        for (let i of val) {
          if (i.id == that.$store.state.chooseTakeAddress.id) {
            that.$store.commit("addressUpdate", {
              chooseTakeAddress: i,
            });
            break;
          }
        }
        if (that.$store.state.chooseSendAddress.id && !that.isSame) {
          for (let i of val) {
            if (i.id == that.$store.state.chooseSendAddress.id) {
              that.$store.commit("addressUpdate", {
                chooseSendAddress: i,
              });
              break;
            }
          }
        } else {
          for (let i of val) {
            if (i.id == that.$store.state.chooseTakeAddress.id) {
              that.$store.commit("addressUpdate", {
                chooseSendAddress: i,
              });
              break;
            }
          }
        }
      }
    },
  },
};
</script>

<style scoped>
.addDiv {
  background: white;
  padding: 0.3rem 0;
  width: 100%;
}
.left {
  width: 10%;
}

.middle {
  width: 80%;
}

.right {
  width: 10%;
  justify-content: flex-end;
}

.addBottomLine {
  width: 100%;
  border: 0.03rem solid transparent;
  background: linear-gradient(white, white) padding-box,
    repeating-linear-gradient(
        -45deg,
        #1aad19 0,
        #1aad19 12.5%,
        transparent 0,
        transparent 25%,
        #fe812f 0,
        #fe812f 37.5%,
        transparent 0,
        transparent 50%
      )
      0/5em 5em;
}

.same {
  color: white;
  width: 0.4rem;
  height: 0.4rem;
  border-radius: 50%;
}

.noSame {
  border: rgb(194, 194, 194) solid 0.01rem;
  width: 0.4rem;
  height: 0.4rem;
  border-radius: 50%;
  color: rgb(194, 194, 194);
}

.line {
  background: #e4e4e4;
  height: 0.01rem;
}
</style>