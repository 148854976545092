<template>
  <div
    class="content commonText"
    @click="toPage('orderDetail', { orderId: orderInfo.id })"
  >
    <!-- oneOrder -->
    <div class="oneOrderDiv centerDiv maTo30">
      <div class="centerDiv pflex bt" style="width: 95%">
        <div>
          订单号<span
            :class="orderInfo.status == -1 ? 'canUseColor' : 'mainColor'"
            style="margin-left: 0.15rem"
            >{{ orderInfo.orderNo }}</span
          >
        </div>
        <div
          class="status"
          v-if="orderInfo.status !== -1 && orderInfo.status !== 7"
        >
          {{ state[orderInfo.status - 1].text }}
        </div>
        <div class="status canUseColor" v-if="orderInfo.status == -1">
          已取消
        </div>
        <div
          class="status"
          v-if="orderInfo.status == 7 && orderInfo.isSmallShopOrder !== 1"
        >
          已完成
        </div>
        <div
          class="status"
          v-if="orderInfo.status == 7 && orderInfo.isSmallShopOrder == 1"
        >
          已发货
        </div>
      </div>
      <div class="centerDiv maTo30">
        <div class="pflex">
          <div
            class="cflex everyStateList"
            v-for="item in imgList"
            :key="item.url"
          >
            <img class="stateImg" :src="item.url" alt="" />
            <div class="miniText maTo20">{{ item.text }}</div>
          </div>
          <div
            class="cflex everyStateList"
            v-for="item in comImgList"
            :key="item.url"
          >
            <img class="stateImg" :src="item.url" alt="" />
            <div class="miniText maTo20">{{ item.text }}</div>
          </div>
        </div>

        <div class="maTo30" v-if="orderInfo.orderStateVO">
          <div style="line-height: 1.5em">
            {{ orderInfo.orderStateVO.remark }}
          </div>
        </div>

        <div class="maTo10" v-if="orderInfo.picUrlList.length > 0">
          <div
            class="pflex"
            style="justify-content: flex-start; flex-wrap: wrap"
          >
            <img
              class="orderListImg maTo20"
              :src="item"
              alt=""
              v-for="(item, index) in orderInfo.picUrlList"
              :key="index"
              @click.stop="previewImg(orderInfo.picUrlList, index)"
            />
          </div>
        </div>

        <div class="pflex maTo30" style="justify-content: flex-end">
          <span>洗涤数量</span>
          <span class="mainColor" style="margin-left: 0.15rem"
            >x {{ orderInfo.goodsNum }}</span
          >
        </div>

        <div class="pflex maTo30" style="justify-content: flex-end">
          <div>
            <span>预付金额</span>
            <span class="orange" style="margin-left: 0.15rem"
              >¥ {{ orderInfo.prepaidAmount }}</span
            >
          </div>

          <div style="margin-left: 0.15rem">
            <span>实付金额</span>
            <span class="orange" style="margin-left: 0.15rem"
              >¥ {{ orderInfo.actualAmount }}</span
            >
          </div>
        </div>

        <div class="pflex maTo20" style="justify-content: flex-end">
          <!-- <div
            class="orderMiniButton miniText confirmButton mainBgc"
            @click.stop="toPage('evaluate')"
            v-if="orderInfo.status == 6"
          >
            评价
          </div> -->
          <div
            class="orderMiniButton miniText confirmButton mainBgc"
            v-if="orderInfo.status == 1 && $store.state.userInfo.isSteward"
            @click.stop="makeCode"
          >
            查看支付码
          </div>
          <div
            class="orderMiniButton miniText confirmButton mainBgc"
            v-if="
              orderInfo.topaidAmount > 0 &&
              orderInfo.status !== -1 &&
              !$store.state.userInfo.isSteward
            "
            @click.stop="toPay"
          >
            支付¥{{ orderInfo.topaidAmount }}
          </div>
          <div
            class="orderMiniButton miniText cancel"
            @click.stop="cancelOrder"
            v-if="orderInfo.status < 3 && orderInfo.status !== -1"
          >
            取消订单
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      state: [
        {
          url: require("@/images/status/orderList-pic.png"),
          text: "待付款",
        },
        {
          url: require("@/images/status/orderList-pic-1.png"),
          text: "待取件",
        },
        {
          url: require("@/images/status/orderList-pic-2.png"),
          text: "待入厂",
        },
        {
          url: require("@/images/status/orderList-pic-3.png"),
          text: "处理中",
        },
        {
          url: require("@/images/status/orderList-pic-4.png"),
          text: "待收货",
        },
        {
          url: require("@/images/status/orderList-pic-5.png"),
          text: "待评价",
        },
      ],
      completeState: [
        {
          url: require("@/images/status/state-pic.png"),
          text: "待付款",
        },
        {
          url: require("@/images/status/state-pic-1.png"),
          text: "待取件",
        },
        {
          url: require("@/images/status/state-pic-2.png"),
          text: "待入厂",
        },
        {
          url: require("@/images/status/state-pic-3.png"),
          text: "待处理",
        },
        {
          url: require("@/images/status/state-pic-4.png"),
          text: "待收货",
        },
        {
          url: require("@/images/status/state-pic-5.png"),
          text: "待评价",
        },
      ],
    };
  },
  props: {
    orderInfo: Object,
  },
  computed: {
    imgList() {
      if (this.orderInfo.status == -1) {
        return [];
      } else {
        return this.completeState.slice(0, this.orderInfo.status);
      }
    },
    comImgList() {
      if (this.orderInfo.status == -1) {
        return this.state;
      } else {
        return this.state.slice(this.orderInfo.status, this.state.length);
      }
    },
  },
  methods: {
    makeCode() {
      this.$emit("makeCode", this.orderInfo);
    },
    toPay() {
      const that = this;
      that.$emit("pay", that.orderInfo);
    },
    cancelOrder() {
      const that = this;
      that.$dialog
        .confirm({
          title: "提示",
          message: "取消订单？",
          confirmButtonColor: that.common.mainColor,
        })
        .then(() => {
          // on confirm
          that.http
            .get("mjj/delete.json", {
              token: that.$store.state.token,
              // openId: that.$store.state.openId,
              orderId: that.orderInfo.id,
              status: -1,
            })
            .then(() => {
              that.$emit("updateOrderList");
            });
        })
        .catch(() => {
          // on cancel
        });
    },
  },
};
</script>

<style scoped>
.oneOrderDiv {
  background: white;
  border-radius: 0.15rem;
  padding: 0.25rem 0;
}

.bt {
  justify-content: space-between;
}

.everyStateList {
  width: 16.6%;
}

.stateImg {
  width: 0.572rem;
  height: 0.495rem;
}

.orderMiniButton {
  padding: 0.1rem 0.23rem;
  border-radius: 0.6rem;
  margin-left: 0.15rem;
}

.cancel {
  border: #9e9e9e solid 0.01rem;
  color: #9e9e9e;
}

.confirmButton {
  color: #ffffff;
}

.orderListImg {
  width: 23%;
  margin-right: 0.1rem;
}
</style>