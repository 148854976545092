<template>
  <div>
    <!-- <van-nav-bar title="大鲸洗" left-text="返回" left-arrow @click-left="back" /> -->
    <iframe :src="webSrc" frameborder="0"></iframe>
  </div>
</template>

<script>
export default {
  data() {
    return {
      webSrc: "",
    };
  },
  mounted() {
    this.webSrc =
      "https://weixin.dajingxiapp.com/static/web/" +
      this.$route.query.webSrc +
      ".html";
  },
};
</script>

<style scoped>
iframe {
  width: 100vw;
  height: 100vh;
}
</style>