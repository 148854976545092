function envConfig() {
    let env = 'dev' // save  dev
    let envConfigObj = {}
    switch (env) {
        case 'dev':
            envConfigObj = {
                // baseUrl: "https://weixin.dajingxiapp.cn/apiv2",
                baseUrl: "/api",
                env: env,
                supplementGoodId: 1865,
                supplementGoodImg: "https://file.haishenxiyi.com/factory/2/2021-11-05/Fk7trGVD4y9QuymjZUCFbVMQC14i",
                index: "http://192.168.8.72:8080/#/"
            }
            break;
        case 'save':
            envConfigObj = {
                baseUrl: "https://weixin.dajingxiapp.com/apiv2",
                env: env,
                supplementGoodId: 1930,
                supplementGoodImg: "https://file.haishenxiyi.com/factory/2/2021-11-05/Fk7trGVD4y9QuymjZUCFbVMQC14i",
                index: ""
            }
            break;
    }
    return envConfigObj
}

export default envConfig;