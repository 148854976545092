import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

const store = new Vuex.Store({
    state: {
        userInfo: {},
        token: "",
        chooseMap: {},
        navHeight: "",
        chooseTakeAddress: {},
        chooseSendAddress: {},
        addressSame: true,
        useCouponList: [],
        indexShowCoupon: true
    },
    mutations: {
        memberUpdate(state, userInfo) {
            state.userInfo = userInfo
            state.token = userInfo.token
        },
        chooseMapUpdate(state, mapInfo) {
            state.chooseMap = mapInfo
        },
        navHeightUpdate(state, navHeight) {
            state.navHeight = navHeight
        },
        addressUpdate(state, addressChoose) {
            if (addressChoose.chooseTakeAddress) {
                state.chooseTakeAddress = addressChoose.chooseTakeAddress
            }
            if (addressChoose.chooseSendAddress) {
                state.chooseSendAddress = addressChoose.chooseSendAddress
            }
        },
        addressSameUpdate(state, bool) {
            state.addressSame = bool
        },
        useCouponListUpdate(state, useCouponList) {
            state.useCouponList = useCouponList
        },
        indexShowCouponUpdate(state, bool) {
            state.indexShowCoupon = bool
        }
    }
})

export default store