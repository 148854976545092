<template>
  <div class="content commonText">
    <!-- addressList -->
    <div
      class="centerDiv"
      style="margin-bottom: 3rem; padding-top: 0.1rem"
      v-if="addressList.length > 0"
    >
      <div
        class="addressDiv"
        v-for="(item, index) in addressList"
        :key="index"
        @click="choose(index)"
      >
        <div class="centerDiv pflex">
          <!-- address -->
          <div class="addressInfo">
            <div class="addressHead">
              {{ item.name }} ｜ {{ item.telphone }}
            </div>
            <div class="infoDiv miniText">
              {{ item.province }}{{ item.city }}{{ item.district
              }}{{ item.building }}{{ item.address }}
            </div>
          </div>
          <!-- edit -->
          <div class="pflex edit">
            <div>
              <van-icon
                name="edit"
                size="20"
                @click.stop="toPage('editAddress', item)"
              />
            </div>
            <div>
              <van-icon
                name="delete-o"
                size="20"
                @click.stop="deleteAddress(item.id)"
              />
            </div>
          </div>
        </div>
      </div>
    </div>

    <div v-else>
      <van-empty description="您还没有地址" />
    </div>
    <!-- 新增地址 -->
    <div class="bottomView">
      <div class="addButton mainBgc pflex" @click="toPage('editAddress')">
        新增地址
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      addressList: [],
      canChoose: false,
      chooseType: "",
    };
  },
  mounted() {
    const that = this;
    that.getAddress();
    if (that.$route.query.choose) {
      that.canChoose = true;
      that.chooseType = that.$route.query.type;
    }
  },
  methods: {
    getAddress() {
      const that = this;
      that.http
        .get("address/list.json", {
          token: that.$store.state.token,
          // token: "d468dbce-eafa-4610-808e-e3a7e6ca5bcd",
        })
        .then((res) => {
          that.addressList = res.data.addressVOList;
          // that.$forceUpdate()
        });
    },
    deleteAddress(id) {
      const that = this;
      that.$dialog
        .confirm({
          title: "提示",
          message: "删除地址？",
          confirmButtonColor: that.common.mainColor,
        })
        .then(() => {
          that.http
            .get("address/delete.json", {
              token: that.$store.state.token,
              addressId: id,
            })
            .then((res) => {
              if (res.status == 200) {
                that.$toast("删除成功");
              }
              that.getAddress();
            });
        })
        .catch(() => {
          // on cancel
        });
    },
    choose(index) {
      if (this.chooseType == "take") {
        this.$store.commit("addressUpdate", {
          chooseTakeAddress: this.addressList[index],
        });
      }
      if (this.chooseType == "send") {
        this.$store.commit("addressUpdate", {
          chooseSendAddress: this.addressList[index],
        });
      }
      setTimeout(() => {
        this.back();
      });
    },
  },
};
</script>

<style scoped>
.addressDiv {
  width: 100%;
  background: #ffffff;
  border-radius: 0.1rem;
  padding: 0.3rem 0;
  margin-top: 0.2rem;
}

.addressInfo {
  width: 75%;
}

.edit {
  width: 25%;
  justify-content: space-around;
}

.addressHead {
  font-size: 0.3rem;
  /* font-weight: bold; */
}

.infoDiv {
  margin-top: 0.2rem;
  line-height: 2em;
}

.bottomView {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
}

.addButton {
  color: #ffffff;
  padding: 0.2rem 0;
  width: 90%;
  margin: 0 auto;
  border-radius: 0.1rem;
  margin-bottom: 0.5rem;
}
</style>