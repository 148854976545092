<template>
  <div>
    <div class="content" style="padding-top: 0.1rem">
      <div style="width: 85%; margin: 0.4rem auto 0 auto">
        <!-- 手机号输入 -->
        <div class="pflex inputView">
          <input
            type="number"
            placeholder-class="phcolor"
            style="width: 95%; margin: 0 auto"
            placeholder="请输入手机号"
            pattern="[0-9]*"
            v-model="inputTel"
          />
        </div>
        <!-- 验证码输入与获取 -->
        <div class="inputView pflex" style="margin-top: 0.3rem">
          <div
            class="pflex"
            style="width: 95%; margin: 0 auto; justify-content: space-between"
          >
            <input
              type="number"
              placeholder-class="phcolor"
              style="width: 50%"
              placeholder="请输入验证码"
              pattern="[0-9]*"
              v-model="inputCode"
            />
            <div style="width: 50%; justify-content: flex-end" class="pflex">
              <div
                v-if="istime"
                class="codeButton pflex mainBgc"
                @click="getCode"
              >
                发送验证码
              </div>
              <div v-else class="pflex mainColor">还有{{ count }}秒</div>
            </div>
          </div>
        </div>
        <!-- 温馨提示 -->
        <div class="maTo30 miniText">
          温馨提示：更换绑定手机号，不影响您的账户其他信息
        </div>
        <!-- 绑定按钮 -->
        <div class="maTo30">
          <van-button :color="common.mainColor" size="large" @click="login"
            >登录</van-button
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "App",
  data() {
    return {
      value: "",
      istime: true,
      inputTel: "",
      inputCode: "",
      count: 0, // 剩余时间
      timer: null,
    };
  },
  methods: {
    // 验证码
    getCode() {
      let that = this;
      let TIME_COUNT = 30;
      const reg = /^[1][3,4,5,6,7,8,9][0-9]{9}$/;
      if (!reg.test(that.inputTel)) {
        this.$toast("手机号填写错误");
      } else {
        that.http
          .get("index/validate.json", { telphone: that.inputTel })
          .then(() => {
            that.$toast("验证码已发送");
            if (!that.timer) {
              that.count = TIME_COUNT;
              that.istime = false;
              that.timer = setInterval(() => {
                if (that.count > 0 && that.count <= TIME_COUNT) {
                  that.count--;
                } else {
                  that.istime = true;
                  clearInterval(this.timer);
                  that.timer = null;
                }
              }, 1000);
            }
          });
      }
    },
    login() {
      let that = this;
      if (that.inputCode.length == 0) {
        this.$toast("验证码未填写");
      } else {
        that.http
          .get("mjj/shopLogin.json", {
            telphone: that.inputTel,
            code: that.inputCode,
          })
          .then((res) => {
            if (res.data.stateVO.code == 200) {
              that.userInfoUpdate(res.data.memberVO);
              that.redPage("index");
              window.location.reload();
            }
          });
      }
    },
  },
};
</script>

<style scoped >
.inputView {
  background-color: #e9e9e9;
  width: 100%;
  height: 0.92rem;
  font-size: 0.28rem;
}

input {
  height: 100%;
  color: #575757;
}

.codeButton {
  width: 1.68rem;
  height: 0.55rem;
  color: #ffffff;
  font-size: 0.24rem;
  border-radius: 0.06rem;
}

.phcolor {
  color: #cccccc;
}

.checkButton {
  background-color: #ff7713;
  width: 100%;
  height: 0.92rem;
  margin-top: 0.5rem;
  color: #ffffff;
  font-size: 0.28rem;
}
</style>