<template>
  <div class="content commonText">
    <!-- balance -->
    <div class="cflex balanceDiv commonText">
      <div>
        <span>¥</span>
        <span style="font-size: 0.7rem; margin-left: 0.2rem">{{
          $store.state.userInfo.balance
        }}</span>
      </div>
      <div class="maTo30">账户余额</div>
    </div>
    <div
      class="maTo30 centerDiv"
      v-if="orderInfo.topaidAmount && orderInfo.topaidAmount > 0"
    >
      <van-button type="primary" block round color="#1aad19" @click="topPay"
        >支付 ¥ {{ orderInfo.topaidAmount }}</van-button
      >
    </div>
  </div>
</template>

<script>
export default {
  name: "App",
  data() {
    return {
      orderId: "",
      orderInfo: {},
    };
  },
  mounted() {
    const that = this;
    if (that.$route.params.orderId) {
      that.orderId = that.$route.params.orderId;
    } else {
      that.$dialog.alert({
        title: "温馨提示",
        message: "订单错误，请重新扫描二维码",
        confirmButtonColor: that.common.mainColor,
      });
    }
    if (!that.$store.state.userInfo.isSteward) {
      that.http
        .get("mjj/login.json", {
          token: that.$store.state.token,
        })
        .then((val) => {
          if (val.data.memberVO.token) {
            that.userInfoUpdate(val.data.memberVO);
          }
        });
      that.http
        .get("order/detail.json", {
          token: that.$store.state.token,
          orderId: that.orderId,
        })
        .then((res) => {
          that.orderInfo = res.data.orderVO;
        });
    }
  },
  methods: {
    topPay() {
      const that = this;
      that.http
        .post("mjj/pay.json", {
          orderId: that.orderId,
          token: that.$store.state.token,
        })
        .then((res) => {
          if (res.data.stateVO.code == 200) {
            that.redPage("orderList");
          }
        });
    },
  },
};
</script>

<style scoped >
.balanceDiv {
  background: white;
  height: 3.5rem;
}

.balanceCh {
  padding: 0.3rem 0;
  background: #ffffff;
}
</style>