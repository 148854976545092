<template>
  <div class="content commonText">
    <div class="head">
      <!-- userInfo -->
      <div
        class="centerDiv pflex"
        style="justify-content: flex-start; width: 86%"
      >
        <!-- avatar -->
        <!-- <div
          v-if="!$store.state.userInfo.token"
          class="avatar pflex"
          @click="toLogin"
        >
          <span>登录</span>
        </div> -->
        <div class="pflex">
          <!-- <img class="avatar" :src="$store.state.userInfo.avatar" alt="" /> -->
          <div class="avatar pflex">
            <!-- {{ $store.state.userInfo.name[0] }} -->
            <van-image
              width="100%"
              height="100%"
              fit="cover"
              src="https://static.dajingxiapp.com/miniApp/meijiajieLogo.jpg"
              :radius="5"
            />
          </div>
        </div>
        <div class="cflex info">
          <div>
            {{ $store.state.userInfo.name || "中信用户" }}
            <span v-if="$store.state.userInfo.isSteward">（收衣点店长）</span>
          </div>
        </div>
      </div>
      <!-- functions -->
      <div
        class="pflex centerDiv function"
        v-if="!$store.state.userInfo.isSteward"
      >
        <!-- <div class="everyFunction cflex">
          <img src="@/images/recharge.png" alt="" />
          <div class="maTo20">充值</div>
        </div> -->
        <div class="everyFunction cflex" @click="toPage('balance')">
          <img src="@/images/balance.png" alt="" />
          <div class="maTo20">账户明细</div>
          <div style="width: 100%; color: white" class="pflex maTo30">
            ￥ {{ balance }}
          </div>
        </div>
        <!-- <div class="everyFunction cflex" @click="toPage('coupon')">
          <img src="@/images/coupon.png" alt="" />
          <div class="maTo20">卡券包</div>
        </div> -->
      </div>
    </div>

    <!-- 功能模块 -->
    <div
      style="
        width: 100%;
        flex-wrap: wrap;
        justify-content: flex-start;
        margin-top: 0.3rem;
      "
      class="pflex"
    >
      <div class="functionDiv cflex" @click="toPage('address')">
        <img src="@/images/address.png" alt="" />
        <div class="maTo20">我的地址</div>
      </div>

      <!-- <div class="functionDiv cflex" @click="toPage('groupCoupon')">
        <img src="@/images/tuan.png" alt="" />
        <div class="maTo20">红包兑换</div>
      </div> -->
      <!-- 
      <div class="functionDiv cflex" @click="toCe">
        <img src="@/images/kefu.png" alt="" />
        <div class="maTo20">客服中心</div>
      </div> -->

      <!-- <div class="functionDiv cflex">
        <img src="@/images/tuan.png" alt="" />
        <div class="maTo20">团购核销</div>
      </div> -->

      <div class="functionDiv cflex" @click="toWeb('zhongxin/faq_zhongxin')">
        <img src="@/images/question.png" alt="" />
        <div class="maTo20">常见问题</div>
      </div>

      <div class="functionDiv cflex" @click="toWeb('zhongxin/user_agreement')">
        <img src="@/images/xieyi.png" alt="" />
        <div class="maTo20">用户协议</div>
      </div>
      <!-- <div class="functionDiv cflex" @click="toWeb('service_area_zhonghang')">
        <img src="@/images/fanwei.png" alt="" />
        <div class="maTo20">服务范围</div>
      </div> -->
      <!-- <div class="functionDiv cflex" @click="toWeb('dajingxi_introduction')">
        <img src="@/images/aboutUs.png" alt="" />
        <div class="maTo20">关于我们</div>
      </div> -->
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      balance: 0,
    };
  },
  mounted() {
    const that = this;
    if (!that.$store.state.userInfo.isSteward) {
      that.http
        .get("mjj/login.json", {
          token: that.$store.state.token,
        })
        .then((res) => {
          if (res.data.memberVO.token) {
            that.userInfoUpdate(res.data.memberVO);
            that.balance = res.data.memberVO.balance;
          }
        });
    }
  },
  methods: {
    toCe() {
      let url = "https://weixin.dajingxiapp.com/static/web/ce.html";
      if (this.$store.state.userInfo.id) {
        url =
          "https://weixin.dajingxiapp.com/static/web/ce.html?uid=" +
          this.$store.state.userInfo.id +
          "&name=【美家洁用户】" +
          this.$store.state.userInfo.name +
          "&pic=" +
          this.$store.state.userInfo.avatar;
      }
      // console.log(url)
      window.location.href = url;
    },
    toLogin() {
      this.$router.push({ path: "/login" });
    },
    toPage(page) {
      this.$router.push({
        path: "/" + page,
      });
    },
    toWeb(url) {
      this.$router.push({
        name: "iframe",
        query: {
          webSrc: url,
        },
      });
    },
  },
};
</script>

<style scoped>
.head {
  padding: 0.3rem 0;
  width: 100%;
  background: #676d7a;
}

.avatar {
  width: 1.1rem;
  height: 1.1rem;
  color: #ffffff;
}

.info {
  color: white;
  height: 1.1rem;
  align-items: flex-start;
  margin-left: 0.2rem;
  font-size: 0.24rem;
}

.everyFunction > img {
  width: 0.8rem;
}

.everyFunction {
  color: white;
}

.function {
  width: 80%;
  font-size: 0.23rem;
  margin-top: 0.5rem;
}

.functionDiv {
  width: 33.3%;
  padding: 0.2rem 0;
  color: #8a8a8a;
  margin-bottom: 0.5rem;
}

.functionDiv > img {
  width: 0.7rem;
}
</style>
