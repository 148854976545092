<template>
  <div class="content">
    <van-nav-bar title="城市" left-text="返回" left-arrow @click-left="back" />
    <!-- location -->
    <div class="centerDiv cflex">
      <!-- 定位 -->
      <div class="locationView">
        <div class="title">定位</div>
        <div class="location pflex commonText">北京市</div>
      </div>

      <!-- 热门城市 -->
      <div class="locationView">
        <div class="title">热门城市</div>
        <div
          style="width: 100%; flex-wrap: wrap; justify-content: flex-start"
          class="pflex"
        >
          <div
            class="location pflex commonText"
            v-for="(item, index) in hotCity"
            :key="index"
          >
            {{ item }}
          </div>
        </div>
      </div>
    </div>
    <!-- 城市选择 -->
    <div class="locationView">
      <div class="centerDiv">
        <div class="title centerDiv">城市选择</div>
      </div>
      <!-- 左右选择 -->
      <div class="lrChoose">
        <div class="left"></div>
        <div class="right"></div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      cityList: {},
      cityItems: [],
      hotCity: ["北京市", "上海市", "深圳市", "广州市", "天津市", "西安市"],
    };
  },
  mounted() {
    const that = this;
    that.http.get("city/list.json").then((res) => {
      that.cityList = res.data.cityMap;
    });
  },
};
</script>

<style scoped>
.location {
  background-color: #ffffff;
  width: 22%;
  padding: 0.15rem 0;
  border-radius: 0.1rem;
  margin-bottom: 0.16rem;
  margin-left: 0.2rem;
  border: 0.01rem solid #e4e4e4;
  color: #6d6d6d;
}

.locationView {
  width: 100%;
}

.title {
  color: #111111;
  font-weight: bold;
  font-size: 0.3rem;
  margin: 0.38rem 0;
}

.lrChoose {
  width: 100%;
}
</style>