<template>
  <div class="content commonText">
    <!-- statusList -->
    <van-steps
      direction="vertical"
      :active="0"
      :active-color="common.mainColor"
    >
      <van-step v-for="(item, index) in orderStateList" :key="index">
        <h3>{{ item.title }}</h3>
        <p class="maTo20">{{ item.remark }}</p>
        <p class="maTo20">{{ item.createTime }}</p>
      </van-step>
    </van-steps>
  </div>
</template>

<script>
export default {
  data() {
    return {
      orderStateList: [],
    };
  },
  mounted() {
    const that = this;
    if (that.$route.params.orderId) {
      that.http
        .get("order/statusList.json", {
          token: that.$store.state.token,
          orderId: that.$route.params.orderId,
        })
        .then((res) => {
          that.orderStateList = res.data.orderStateVOList;
        });
    } else {
      that.toPage("index");
    }
  },
};
</script>