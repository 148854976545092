<template>
  <div class="content commonText">
    <div class="popup cflex" v-show="showCouponChoose">
      <div
        ref="wrapper"
        v-if="couponList.length > 0"
        style="width: 100%; height: 75%; overflow: hidden"
      >
        <div class="centerDiv" style="padding: 0.6rem 0">
          <div
            class="maTo20 pflex bt"
            v-for="(item, index) in couponList"
            :key="index"
            @click="chooseCoupon(index, item)"
          >
            <div class="chooseradio">
              <div v-if="item.choose == 1" class="choose mainBgc"></div>
            </div>
            <div class="couponDiv pflex">
              <div
                class="cflex"
                style="width: 30%"
                :class="item.isReuse == 1 ? 'mainColor' : 'orange'"
              >
                <div>
                  <span>¥</span>
                  <span style="font-size: 0.45rem; margin-left: 0.15rem">{{
                    item.amount
                  }}</span>
                </div>
                <div class="maTo20" style="font-size: 0.23rem">
                  <span v-if="item.limitAmount > 0"
                    >满{{ item.limitAmount }}可用</span
                  >
                  <span v-else>无门槛红包</span>
                </div>
              </div>
              <div style="width: 50%">
                <div>{{ item.name }}</div>
                <div class="miniText maTo20">有效期至{{ item.endTime }}</div>
              </div>
              <div
                style="width: 20%; font-size: 0.23rem"
                class="useButton mainColor pflex"
                v-if="item.isReuse == 1"
              >
                同享券
              </div>

              <div
                style="width: 20%; font-size: 0.23rem"
                class="useButton pflex orange"
                v-else
              >
                互斥券
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="bottomButtonGroup pflex" v-if="couponList.length > 0">
        <div class="centerDiv pflex bt">
          <div class="left couponButton pflex" @click="clearCouponList">
            不使用红包
          </div>
          <div class="right couponButton pflex mainBgc" @click="autoChoose">
            自动搭配
          </div>
          <div class="right couponButton pflex mainBgc" @click="confirmUse">
            确定使用
          </div>
        </div>
      </div>

      <div v-else>
        <van-empty description="您还没有红包" />
      </div>
    </div>
    <div class="over" v-if="showCouponChoose" @click="closeCouponChoose"></div>
  </div>
</template>

<script>
import BScroll from "better-scroll";
export default {
  props: {
    showCouponChoose: {
      type: Boolean,
      default: false,
    },
    couponList: Array,
    totalAmount: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      useCouponList: [],
    };
  },
  methods: {
    closeCouponChoose() {
      this.$emit("closeChoose", false);
    },
    confirmUse() {
      let that = this;
      let useCouponList = [];
      for (let i of that.couponList) {
        if (i.choose == 1) {
          useCouponList.push(i);
        }
      }
      that.$store.commit("useCouponListUpdate", useCouponList);
      that.closeCouponChoose();
    },
    chooseCoupon(index, item) {
      const that = this;
      if (item.limitAmount > that.totalAmount) {
        that.$dialog.alert({
          title: "提示",
          message: "订单金额未达到满减门槛",
          confirmButtonColor: that.common.mainColor,
        });
      } else {
        // 选择叠加券
        if (item.isReuse == 1) {
          for (let i in that.couponList) {
            // 取消所有的互斥券
            if (that.couponList[i].isReuse == 0) {
              that.couponList[i].choose = 0;
            } else {
              if (i == index) {
                // 如果是之前选过的叠加券，取消选择
                if (that.couponList[i].choose == 1) {
                  that.couponList[index].choose = 0;
                } else {
                  // 如果未选择，就添加选择
                  that.couponList[index].choose = 1;
                }
              }
            }
          }
        } else {
          // 非叠加券的情况
          for (let i in that.couponList) {
            if (that.couponList[i].id == item.id) {
              that.couponList[i].choose = 1;
            } else {
              that.couponList[i].choose = 0;
            }
          }
        }
        //   that.$store.commit("useCouponListUpdate", []);
        that.$forceUpdate();
      }
    },
    clearCouponList() {
      this.$store.commit("useCouponListUpdate", []);
      this.closeCouponChoose();
    },
    autoChoose() {
      window.location.reload();
    },
    initScroll() {
      this.$nextTick(() => {
        if (!this.Scroll) {
          this.Scroll = new BScroll(this.$refs.wrapper, {
            click: true, // 配置允许点击事件
            scrollY: true, // 开启纵向滚动
          });
        } else {
          this.Scroll.refresh(); // 重新计算 better-scroll，当 DOM 结构发生变化的时确保滚动效果正常
        }
      });
    },
  },
  watch: {
    showCouponChoose(val) {
      if (val) {
        setTimeout(() => {
          this.initScroll();
        }, 20);
        if (this.$store.state.useCouponList.length > 0) {
          for (let j of this.couponList) {
            j.choose = 0;
          }
          for (let i of this.$store.state.useCouponList) {
            for (let j of this.couponList) {
              if (i.id == j.id) {
                j.choose = 1;
              }
            }
          }
        } else {
          for (let j of this.couponList) {
            j.choose = 0;
          }
        }
      }
    },
  },
};
</script>

<style scoped>
.couponDiv {
  width: 90%;
  background: white;
  padding: 0.4rem 0;
  /* border:#e9e9e9 solid 0.01rem */
  box-shadow: 0px 0.05rem 0.13rem #d8d8d8;
}

.chooseradio {
  width: 0.4rem;
  height: 0.4rem;
  border-radius: 50%;
  border: #1aad19 solid 1px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.choose {
  width: 0.15rem;
  height: 0.15rem;
  border-radius: 100%;
}

.bottomButtonGroup {
  width: 100%;
  height: 20%;
}

.couponButton {
  width: 32%;
  padding: 0.3rem 0;
  border-radius: 0.15rem;
}

.right {
  color: #ffffff;
}

.left {
  background: #eee9e9;
}

.over {
  position: fixed;
  width: 100%;
  height: 100%;
  opacity: 0.7;
  filter: alpha(opacity=70);
  top: 0;
  left: 0;
  z-index: 1;
  background-color: #111111;
}

.popup {
  position: fixed;
  font-size: 0.25rem;
  height: 60%;
  width: 90%;
  background-color: white;
  border-radius: 0.15rem;
  left: 50%;
  top: 50%;
  display: flex;
  flex-direction: column;
  align-content: center;
  transform: translate(-50%, -50%);
  z-index: 1000;
}
</style>