<template>
  <div class="content">
    <iframe
      :src="webSrc"
      frameborder="0"
      :style="iframeHeight"
      v-if="webSrc.length > 0"
    ></iframe>
  </div>
</template>

<script>
export default {
  data() {
    return {
      webSrc: "",
      iframeHeight: "",
    };
  },
  mounted() {
    // this.iframeHeight =
    //   "height:" +
    //   (document.documentElement.clientHeight - this.$refs.navvar.offsetHeight) +
    //   "px";
    // this.iframeHeight =
    //   "height:" + document.documentElement.clientHeight + "px";
    console.log();
    let navHeight = parseFloat(
      this.$store.state.navHeight.substr(
        0,
        this.$store.state.navHeight.length - 2
      )
    );
    this.iframeHeight =
      "height:" + (document.documentElement.clientHeight - navHeight) + "px";
    if (this.$store.state.userInfo.id) {
      console.log(
        "https://weixin.dajingxiapp.com/static/web/ce.html?uid=" +
          this.$store.state.userInfo.id +
          "&name=【mei jia jie用户】" +
          this.$store.state.userInfo.name +
          "&pic=" +
          this.$store.state.userInfo.avatar
      );
      this.webSrc =
        "https://weixin.dajingxiapp.com/static/web/ce.html?uid=" +
        this.$store.state.userInfo.id +
        "&name=【美家洁用户】" +
        this.$store.state.userInfo.name +
        "&pic=" +
        this.$store.state.userInfo.avatar;
    } else {
      this.webSrc =
        "https://weixin.dajingxiapp.com/static/web/ce.html?name=【美家洁游客用户】";
    }
  },
};
</script>

<style scoped>
iframe {
  width: 100vw;
}
</style>