<template>
  <div class="relative">
    <div
      class="ball"
      ref="ball"
      v-for="(d, i) in balls"
      :key="i"
      :style="
        d.inited
          ? 'transition: transform .5s ease-in; transform: translate3d(0, ' +
            offsetY +
            'px,0); top: ' +
            ballY +
            'px;'
          : ''
      "
    >
      <div
        class="inner arbg"
        :style="
          d.inited
            ? 'transition: transform .5s linear; transform: translate3d( ' +
              offsetX +
              'px,0,0); left: ' +
              ballX +
              'px; opacity: 1;'
            : ''
        "
      ></div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    cartBasketRect: Object,
  },
  data() {
    return {
      offsetX: 0,
      offsetY: 0,
      ballX: 0,
      ballY: 0,
      balls: {},
      lastEvent: "",
      lastId: "",
    };
  },
  created() {
    let balls = [];
    for (let i = 0; i < 5; i++) {
      balls.push({
        inited: false,
      });
    }
    this.balls = balls;
  },
  methods: {
    addToCart(e, id) {
      if (!id) {
        this.lastId = "";
        return;
      }

      const self = this;
      if (self.lastId == id) {
        e = self.lastEvent;
      } else {
        self.lastId = id;
        self.lastEvent = e;
      }
      let ballX = e.clientX - 10;
      let ballY = e.clientY - 9;
      self.offsetX = -Math.abs(self.cartBasketRect.left - ballX + 10);
      self.offsetY = Math.abs(
        self.cartBasketRect.top - ballY + self.cartBasketRect.height / 1.5
      );
      self.ballX = ballX;
      self.ballY = ballY;

      for (let i = 0; i < 5; i++) {
        if (!self.balls[i].inited) {
          self.balls[i].inited = true;
          setTimeout(() => {
            self.balls[i].inited = false;
          }, 500);
          break;
        }
      }
    },
  },
};
</script>


<style scoped>
.ball {
  position: fixed;
  z-index: 999;
}

.ball .inner {
  background: red;
  width: 0.3rem;
  height: 0.3rem;
  position: fixed;
  border-radius: 50%;
  opacity: 0;
}
</style>
