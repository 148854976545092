<template>
  <div class="content commonText">
    <!-- tab -->
    <van-tabs
      v-model="tabActive"
      :color="common.mainColor"
      @change="changeTab"
      sticky
      :offset-top="$store.state.navHeight"
    >
      <van-tab v-for="(item, index) in tab" :key="index" :title="item.tab">
        <!-- couponList -->
        <div
          class="centerDiv"
          v-if="couponList.length > 0"
          style="margin-bottom: 1rem"
        >
          <!-- v-for -->
          <div
            class="couponDiv maTo30 pflex"
            v-for="(item, index) in couponList"
            :key="index"
          >
            <div
              class="cflex"
              style="width: 30%"
              :style="
                'color:' +
                (tabActive == 0 ? common.mainColor : common.canUseColor)
              "
            >
              <div>
                <span>¥</span>
                <span style="font-size: 0.45rem; margin-left: 0.15rem">{{
                  item.amount
                }}</span>
              </div>
              <div class="maTo20" style="font-size: 0.23rem">
                <span v-if="item.limitAmount > 0"
                  >满{{ item.limitAmount }}可用</span
                >
                <span v-else>无门槛红包</span>
              </div>
            </div>
            <div style="width: 50%">
              <div style="line-height: 1.5em">{{ item.name }}</div>
              <div class="miniText maTo20">有效期至{{ item.endTime }}</div>
            </div>
            <div
              style="width: 20%; font-size: 0.23rem"
              class="useButton mainColor pflex"
              @click="toPage('index')"
              v-if="tabActive == 0"
            >
              立即使用
            </div>

            <div
              style="width: 20%; font-size: 0.23rem"
              :style="'color:' + common.canUseColor"
              class="useButton pflex"
              v-else
            >
              无法使用
            </div>
          </div>
        </div>

        <div v-else>
          <van-empty description="您还没有红包" />
        </div>
      </van-tab>
    </van-tabs>
  </div>
</template>

<script>
export default {
  name: "App",
  data() {
    return {
      tab: [{ tab: "未使用" }, { tab: "已使用" }, { tab: "已过期" }],
      tabActive: 0,
      couponList: [],
    };
  },
  mounted() {
    const that = this;
    that.http
      .get("coupon/list.json", {
        token: that.$store.state.token,
        // openId: that.$store.state.openId,
        state: 1,
      })
      .then((res) => {
        that.couponList = res.data.memberCouponVOList;
      });
  },
  methods: {
    changeTab(e) {
      const that = this;
      that.couponList = [];
      let state = 1;
      if (e == 0) {
        state = 1;
      }
      if (e == 1) {
        state = 3;
      }
      if (e == 2) {
        state = 4;
      }
      that.http
        .get("coupon/list.json", {
          token: that.$store.state.token,
          // openId: that.$store.state.openId,
          state: state,
        })
        .then((res) => {
          that.couponList = res.data.memberCouponVOList;
        });
    },
  },
};
</script>

<style scoped>
.couponDiv {
  width: 100%;
  background: white;
  padding: 0.4rem 0;
}

/* .tab {
  position: absolute;
  top: 0;
  z-index: 999;
  left: 0;
} */
</style>