<template>
  <div class="content">
    <!-- 头部 -->
    <div class="head">
      <div class="centerDiv">
        <div
          style="
            color: #fffeff;
            font-size: 0.4rem;
            font-weight: bold;
            margin-top: 0.1rem;
          "
        >
          洗护售后服务单
        </div>
        <div style="color: #fffeff; line-height: 1.5em" class="maTo30">
          遇到问题请勿担心，我们的客户体验专员会在工作时间尽快联系您。
        </div>
      </div>
    </div>
    <!-- 内容部分 -->
    <!-- 订单号展示 -->
    <div class="module">
      <div class="centerDiv">
        <div class="title pflex">
          <div>订单号</div>
          <div class="mainColor">{{ orderNo }}</div>
        </div>
        <div
          class="commonText title"
          style="margin-bottom: 0; margin-top: 0.4rem"
        >
          对您造成的不便，我们深表抱歉
        </div>
      </div>
    </div>

    <!-- 投诉对象 -->
    <div class="module">
      <div class="centerDiv">
        <div class="title pflex">您要投诉</div>
        <div
          class="pflex"
          style="
            width: 90%;
            margin: 0 auto;
            justify-content: space-between;
            margin-top: 0.3rem;
          "
        >
          <div
            v-for="(item, index) in target"
            :key="index"
            class="commonText pflex"
            @click="chooseTarget(index)"
          >
            <van-checkbox
              v-model="item.choose"
              checked-color="#ED9027"
            ></van-checkbox>
            <div style="margin-left: 0.2rem">{{ item.lableName }}</div>
          </div>
        </div>
      </div>
    </div>

    <!-- 输入框 -->
    <div class="module pflex" style="flex-direction: column">
      <textarea
        name
        id
        placeholder="请您描述投诉原因"
        v-model="remark"
      ></textarea>
      <div
        class="pflex"
        style="justify-content: flex-end; width: 90%; margin: 0 auto"
      >
        <div style="font-size: 0.2rem" class="commonText maTo20">
          已输入
          <span>{{ remark.length }} 字</span>
        </div>
      </div>
    </div>

    <!-- 图片上传 -->
    <div class="module">
      <div class="centerDiv">
        <div class="title pflex">上传您的图片</div>
        <div class="maTo20">
          <van-uploader
            :after-read="afterRead"
            v-model="picList"
            :before-delete="deleteimg"
            :max-count="10"
          />
        </div>
      </div>
    </div>

    <!-- 占位 -->
    <div style="width: 100%; height: 1.5rem"></div>

    <!-- 底部按钮 -->
    <div class="pflex" style="position: fixed; bottom: 0.4rem; width: 100%">
      <div class="mainBgc bottomButton pflex" @click="submission">提交</div>
    </div>
  </div>
</template>

<script>
// import djxChoose from "../components/djxChoose";
import { Uploader, Loading, Toast, Checkbox } from "vant";
import "vant/lib/uploader/style";
import "vant/lib/loading/style";
import "vant/lib/toast/style";
import "vant/lib/checkbox/style";
export default {
  name: "app",
  data() {
    return {
      orderId: "", // orderId
      orderNo: "", // 订单编号
      remark: "", // 填写内容
      picList: [],
      loading: false, // 是否加载状态
      picUrlList: [],
      target: [], // 投诉目标
      userChooseTarget: "",
      token: "",
      memberId: "",
      openId: "",
      userTel: "", // 用户手机号
      isUpload: false, // 是否正在上传图片
    };
  },
  created() {
    let that = this;
    that.orderId = that.$route.query.orderId;
    that.orderNo = that.$route.query.orderNo;
    that.userTel = that.$route.query.tel;
    if (that.$route.query.token) {
      that.token = that.$route.query.token;
    }
    if (that.$route.query.memberId) {
      that.memberId = that.$route.query.memberId;
    }
    if (that.$route.query.openId) {
      that.openId = that.$route.query.openId;
    }
    that.loading = true;
    this.http
      .get("ticket/labelList.json", {
        ticketType: 6,
      })
      .then((res) => {
        that.loading = false;
        that.target = res.data;
        for (let i of that.target) {
          i.choose = false;
        }
      })
      .catch((err) => {
        window.console.loading(err);
        that.loading = false;
      });
  },
  beforeRouteLeave(to, from, next) {
    if (to.path == "/") {
      to.meta.keepAlive = true;
    } else {
      to.meta.keepAlive = false;
    }
    next();
  },
  methods: {
    // 照片删除回调
    deleteimg(e, name) {
      window.console.log(e);
      this.picUrlList.splice(name.index, 1);
      return true;
    },
    // 选择投诉对象
    chooseTarget: function (index) {
      let that = this;
      for (let i in that.target) {
        if (i == index) {
          that.target[i].choose = true;
        } else {
          that.target[i].choose = false;
        }
        this.$forceUpdate();
      }
    },
    afterRead(file) {
      const that = this;
      that.isUpload = true;
      file.status = "uploading";
      file.message = "上传中...";
      //   const fileIndex = that.picList.length - 1;
      const fileObj = file.file; // 当前的图片
      console.log(fileObj);
      that.axios
        .get("upload/token/ticket?token=f069496b-1ae1-4679-aa23-8ce19d819523")
        .then((res) => {
          const fd = new FormData(); // FormData 对象
          fd.append("file", fileObj); // 文件对象 ImgByte // 是 input 中选中的文件列表
          fd.append("token", res.data.credential); // 七牛云upload-token
          const xhr = new XMLHttpRequest(); // XMLHttpRequest 对象
          xhr.open("POST", "https://upload.qiniup.com", true); // post方式，uploadUrl为服务器请求地址，true 该参数规定请求是否异步处理。
          xhr.onreadystatechange = () => {
            that.isUpload = false;
            if (xhr.readyState === 4) {
              if (xhr.status === 200) {
                const response = JSON.parse(xhr.responseText);
                console.log(response, xhr);
                const dataUrl = res.data.uriView + response.key;
                that.picUrlList.push(dataUrl);
                file.status = "done";
                file.message = "上传完成";
              } else {
                file.status = "failed";
                file.message = "上传失败";
              }
            }
          };
          xhr.onerror = () => {};
          xhr.send(fd);
        });
      // const xhr = new XMLHttpRequest() // XMLHttpRequest 对象
      // xhr.open('GET', '/apiv2/apiv2/upload/token?token=56890a69-8658-4217-a1c6-6a27dba9f2f1', true) // post方式，uploadUrl为服务器请求地址，true 该参数规定请求是否异步处理。
      // xhr.onreadystatechange = () => {
      //   if (xhr.readyState === 4) {
      //     if (xhr.status === 200) {
      //       const response = JSON.parse(xhr.responseText)
      //       console.log(response, xhr)
      //       const obj = that.$qs.stringify({
      //         file: fileObj,
      //         token: response.credential
      //       })
      //       that.axios.post('https://upload.qiniup.com/', obj, { headers: {
      //         'Content-Type': 'application/x-www-form-urlencoded'
      //       } }).then(res => {
      //         console.log(res)
      //       }).catch(err => {
      //         console.log(err)
      //       })
      //     } else {
      //     }
      //   }
      // }
      // xhr.onerror = () => {
      //   // reject(new Error(xhr.statusText))
      // }
      // xhr.send()

      // let img = new FormData();
      // img.append("file", file.file);
      // let config = {
      //   headers: { "Content-Type": "multipart/form-data" },
      // };
      // this.axios
      //   .post("/apiv2/fastdfs/upload/image", img, config)
      //   .then((res) => {
      //     that.isUpload = false;
      //     if (res.data.code == 200) {
      //       that.picUrlList.push(res.data.httpUrl);
      //       Toast("上传成功");
      //       file.status = "done";
      //       file.message = "上传完成";
      //     } else {
      //       file.status = "failed";
      //       file.message = "上传失败";
      //     }
      //   })
      //   .catch((err) => {
      //     window.console.loading(err);
      //     that.loading = false;
      //   });
    },
    // 图片压缩
    imgcompress(file, files, fileIndex) {
      const imgIndex = fileIndex;
      const img = document.createElement("img");
      const reader = new FileReader(); // 读取文件资源实例
      reader.readAsDataURL(file); //读取图片资源
      reader.onload = (e) => {
        //读取成功
        img.src = e.target.result;
        const { width: originWidth, height: originHeight } = img; //上传的图片的宽高
        const maxWidth = 1000, //设置一个canvas 的最大宽高
          maxHight = 1000;
        if (originWidth > maxWidth || originHeight > maxHight) {
          //计算出图片的缩放比例
          if (originWidth > originHeight) {
            //宽 大于 高
            const Proportion = Math.ceil(originWidth / maxWidth);
            let targetWidht = parseInt(originWidth / Proportion); //目标的宽度
            let targetHeight = parseInt(originHeight / Proportion); //目标的高度
            window.console.log(imgIndex);
            let quality = 0.9;
            this.createCanvasCompress(
              targetWidht,
              targetHeight,
              img,
              files,
              quality,
              imgIndex
            );
          } else {
            const Proportion = Math.ceil(originHeight / maxHight); //高大于宽
            let targetWidht = parseInt(originWidth / Proportion); //目标的宽度
            let targetHeight = parseInt(originHeight / Proportion); //目标的高度
            window.console.log(imgIndex);
            let quality = 0.9;
            let bold = this.createCanvasCompress(
              targetWidht,
              targetHeight,
              img,
              files,
              quality,
              imgIndex
            );
            window.console.log(bold);
          }
        } else {
          let quality = 0.8;
          window.console.log(imgIndex);
          this.createCanvasCompress(
            originWidth,
            originHeight,
            img,
            files,
            quality,
            imgIndex
          );
        }
      };
    },
    createCanvasCompress(
      targetWidth,
      targetHeight,
      img,
      files,
      quality,
      imgIndex
    ) {
      let that = this;
      window.console.log(imgIndex);
      const fileIndex = imgIndex;
      return new Promise((resolve) => {
        const canvas = document.createElement("canvas");
        const context = canvas.getContext("2d");
        // 设置宽高度为等同于要压缩图片的尺寸
        canvas.width = targetWidth;
        canvas.height = targetHeight;
        context.clearRect(0, 0, targetWidth, targetHeight);
        //将img绘制到画布上
        window.console.log(targetWidth, targetHeight);
        context.drawImage(img, 0, 0, targetWidth, targetHeight);

        let bold = canvas.toBlob(
          function (blob) {
            resolve(blob);
            const formData = new FormData();
            formData.append("file", blob, "xxx.jpg");
            let config = {
              headers: { "Content-Type": "multipart/form-data" },
            };
            // that.loading = true;
            that.axios
              .post("fastdfs/upload/image", formData, config)
              .then((res) => {
                that.isUpload = false;
                // that.loading = false;
                if (res.data.code == 200) {
                  that.picUrlList.push(res.data.httpUrl);
                  that.picList[fileIndex].status = "done";
                  that.picList[fileIndex].message = "上传成功";
                  Toast("上传成功");
                } else {
                  that.picList[fileIndex].status = "failed";
                  that.picList[fileIndex].message = "上传失败";
                  // Toast("上传失败，请稍后再试");
                }
              })
              .catch((err) => {
                window.console.log(err);
                // that.loading = false;
              });
          },
          "image/png",
          quality
        );
        window.console.log(bold);
      });
      // 创建画布
    },

    // 展示解决方案

    // 提交
    submission: function () {
      let that = this;
      if (that.isUpload == false) {
        if (that.remark == "") {
          Toast("请填写问题与原因");
        } else {
          let userchooseTarget = [];
          for (let i of that.target) {
            if (i.choose == true) {
              userchooseTarget.push(i.lableName);
            }
          }
          if (userchooseTarget.length == 0) {
            Toast("请选择您要投诉的对象");
          } else {
            // 验证完成，可以提交
            if (that.memberId == "") {
              that.loading = true;
              let params = new URLSearchParams();
              params.append("labelList", userchooseTarget);
              params.append("orderId", that.orderId);
              params.append("remark", that.remark);
              params.append("picUrlList", that.picUrlList);
              params.append("type", 6);
              that.axios
                .post("ticket/add.json", params)
                .then((res) => {
                  that.loading = false;
                  if (res.data.stateVO.code == 200) {
                    Toast("提交成功");
                    that.$router.push({
                      path: "/afterSale/result",
                      query: { tel: that.userTel },
                    });
                  } else {
                    Toast(res.data.stateVO.msg);
                  }
                })
                .catch((err) => {
                  window.console.log(err);
                  that.loading = false;
                });
            } else {
              that.loading = true;
              let params = new URLSearchParams();
              params.append("labelList", userchooseTarget);
              params.append("orderId", that.orderId);
              params.append("remark", that.remark);
              params.append("type", 6);
              params.append("picUrlList", that.picUrlList);
              params.append("memberId", that.memberId);
              params.append("openId", that.openId);
              params.append("token", that.token);
              that.axios
                .post("ticket/add.json", params)
                .then((res) => {
                  that.loading = false;
                  if (res.data.stateVO.code == 200) {
                    Toast("提交成功");
                    that.$router.push({
                      path: "/afterSale/result",
                      query: { tel: that.userTel },
                    });
                  } else {
                    Toast(res.data.stateVO.msg);
                  }
                })
                .catch((err) => {
                  window.console.log(err);
                  that.loading = false;
                });
            }
          }
        }
      } else {
        Toast("图片正在上传中");
      }
    },
  },
  components: {
    [Uploader.name]: Uploader,
    [Loading.name]: Loading,
    [Toast.name]: Toast,
    [Checkbox.name]: Checkbox,
  },
};
</script>

<style scoped>
.head {
  background-color: #ED9027;
  flex-direction: column;
  padding: 0.8rem 0;
}

.title {
  justify-content: space-between;
  color: #525151;
  margin-bottom: 0.22rem;
}

.module {
  width: 92%;
  background-color: #fffeff;
  border-radius: 0.15rem;
  padding: 0.3rem 0;
  margin: 0 auto;
  margin-top: 0.19rem;
}

.bottomButton {
  color: #fffeff;
  width: 90%;
  height: 0.7rem;
  border-radius: 0.15rem;
}

textarea {
  width: 90%;
  height: 2rem;
  background-color: #f5f5f5;
  border: none;
  border-radius: 0.1rem;
  font-size: 0.3rem;
  padding: 0.1rem;
}

textarea::-webkit-input-placeholder {
  color: #a2a2a2;
}

textarea::-moz-placeholder {
  color: #a2a2a2;
}

textarea:-ms-input-placeholder {
  color: #a2a2a2;
}

.image {
  border: #e4e4e4 solid 0.01rem;
  width: 1.4rem;
  height: 1.4rem;
  border-radius: 0.13rem;
}

.mainColor {
  color: #ED9027;
}

.mainBgc {
  background: #ED9027;
}
</style>