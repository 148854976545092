<template>
  <div class="content">
    <div class="pflex centerDiv" style="width: 85%; margin-top: 0.4rem">
      <img
        src="https://static.dajingxiapp.com/miniApp/WechatIMG216.jpeg"
        alt=""
        style="width: 100%"
      />
    </div>
  </div>
</template>

<script>
export default {
  created() {
    // document.title = "扫一扫添加";
  },
  mounted() {
    // document.title = "扫一扫添加";
  },
};
</script>