<template>
  <div class="commonText">
    <div style="width: 100%">
      <!-- balance -->
      <div class="cflex balanceDiv commonText">
        <div>
          <span>¥</span>
          <span style="font-size: 0.7rem; margin-left: 0.2rem">{{
            $store.state.userInfo.balance
          }}</span>
        </div>
        <div class="maTo30">账户余额</div>
      </div>

      <!-- 明细 -->
      <div v-if="payList.length > 0">
        <!-- v-for -->
        <div
          class="maTo20 balanceCh"
          v-for="(item, index) in payList"
          :key="index"
        >
          <div class="centerDiv">
            <!-- balance -->
            <div class="pflex bt">
              <div>{{ item.remark }}</div>
              <div>{{ item.amount }}</div>
            </div>
            <div class="pflex bt maTo30 miniText">
              <div>订单号:{{ item.outTradeNo }}</div>
            </div>
            <div class="pflex bt maTo30 miniText">
              <div>{{ item.createTime }}</div>
            </div>
          </div>
        </div>
      </div>
      <div v-else>
        <van-empty description="暂时没有明细哦" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "App",
  data() {
    return {
      payList: Array,
    };
  },
  mounted() {
    const that = this;
    that.http
      .get("recharge/payList.json", {
        token: that.$store.state.token,
      })
      .then((res) => {
        if (res.data.memberVO.token) {
          // if (that.$store.state.userInfo.isSteward) {
          //   that.userInfoUpdate(res.data.memberVO);
          // } else {
          that.http
            .get("mjj/login.json", {
              token: that.$store.state.token,
            })
            .then((val) => {
              if (val.data.memberVO.token) {
                that.userInfoUpdate(val.data.memberVO);
              }
            });
          // }
          that.payList = res.data.memberPayVOList;
        }
      });
  },
};
</script>

<style scoped >
.balanceDiv {
  background: white;
  height: 3.5rem;
}

.balanceCh {
  padding: 0.3rem 0;
  background: #ffffff;
}

.bt {
  justify-content: space-between;
}
</style>