<template>
  <div class="content commonText">
    <!-- headTab -->
    <van-tabs
      v-model="tabActive"
      :color="common.mainColor"
      @change="changeTab"
      sticky
      :offset-top="$store.state.navHeight"
    >
      <van-tab v-for="(item, index) in tab" :key="index" :title="item.tab">
        <!-- orderList -->
        <div style="padding-bottom: 2rem" v-if="orderList.length > 0">
          <div v-for="(item, index) in orderList" :key="index">
            <oneOrder
              :orderInfo="item"
              @updateOrderList="getOrderList()"
              @pay="toPay"
              @makeCode="makeCode"
            ></oneOrder>
          </div>
        </div>
        <div v-else>
          <van-empty description="您还没有订单" />
        </div>
      </van-tab>
    </van-tabs>

    <div @click.stop>
      <van-dialog v-model="showCode" title="支付码">
        <div id="canvas-element-id" style="padding: 0.3rem 0"></div>
        <div class="pflex maTo20" style="margin-bottom: 0.3rem">
          请使用企业微信扫描二维码
        </div>
      </van-dialog>
    </div>
  </div>
</template>

<script>
import oneOrder from "./oneOrder";
import QRCode from "qrcodejs2";
export default {
  name: "App",
  data() {
    return {
      tabActive: 0,
      tab: [
        { tab: "进行中", state: 1, pageNo: 1 },
        { tab: "已完成", state: 2, pageNo: 1 },
      ],
      orderList: [],
      code: "",
      showCode: false,
      qrcode: {},
      isLoading: false,
    };
  },
  components: {
    oneOrder,
  },
  mounted() {
    if (this.$route.params.tabActive) {
      this.tabActive = this.$route.params.tabActive;
    }
    this.getOrderList();
    this.bindScroll();
  },
  destroyed() {
    this.clearScroll();
  },
  methods: {
    // 滚动回调函数
    scrollHande() {
      if (this.isLoading) return;
      let scrollH = document.documentElement.scrollHeight;
      let innerH = window.innerHeight;
      let top = document.body.scrollTop || document.documentElement.scrollTop;
      if (scrollH - top - innerH < 100) {
        this.getOrderList();
      }
    },
    bindScroll() {
      window.addEventListener("scroll", this.scrollHande);
    },
    clearScroll() {
      window.removeEventListener("scroll", this.scrollHande);
    },
    makeCode(val) {
      this.showCode = true;
      this.$nextTick(() => {
        if (!this.qrcode.clear) {
          this.qrcode = new QRCode("canvas-element-id", {
            text: "123",
            width: 200,
            height: 200,
            colorDark: "#000000",
            colorLight: "#ffffff",
            correctLevel: QRCode.CorrectLevel.H,
          });
        }
        this.qrcode.clear();
        console.log(val.id);
        this.qrcode.makeCode(
          `https://api.mjj.dajingxiapp.com/redirect/payment/${val.id}?corpId=${this.$store.state.userInfo.corpId}&agentId=${this.$store.state.userInfo.agentId}`
        );
      });
    },
    toPay(orderInfo) {
      const that = this;
      // that.http
      //   .post("mjj/pay.json", {
      //     token: that.$store.state.token,
      //     amount: orderInfo.topaidAmount,
      //     orderId: orderInfo.id,
      //     // payMethod: "zhpay",
      //     // type: "order",
      //     // zhUserId: that.$store.state.userInfo.zhUserId,
      //     // zhUserType: that.$store.state.userInfo.zhUserType || "",
      //   })
      //   .then((payRes) => {
      //     if (payRes.data.stateVO.code == 200) {
      //       window.location.href = payRes.data.prePayVO.mwebUrl;
      //     }
      //   });
      that.http
        .post("mjj/prePay.json", {
          token: that.$store.state.token,
          orderId: orderInfo.id,
          type: "order",
          payMethod: "MWEB",
        })
        .then((payRes) => {
          if (payRes.data.stateVO.code == 200) {
            let url =
              payRes.data.prePayVO.mwebUrl +
              `&redirect_url=${encodeURIComponent(
                "https://mjj.dajingxiapp.com/#/orderList"
              )}`;
            window.location = url;
          }
        });
    },
    // changeTab
    changeTab() {
      this.orderList = [];
      this.tab[0].pageNo = 1;
      this.tab[1].pageNo = 1;
      this.getOrderList();
    },
    getOrderList() {
      const that = this;
      let url = "order/list.json";
      if (that.$store.state.userInfo.isSteward) {
        url = "mjj/orderList.json";
        that.isLoading = true;
        that.http
          .get(url, {
            token: that.$store.state.token,
            state: that.tab[that.tabActive].state,
            recordNum: 10,
            pageNo: that.tab[that.tabActive].pageNo,
          })
          .then((res) => {
            that.isLoading = false;
            if (res.data.orderVOList && res.data.orderVOList.length > 0) {
              that.tab[that.tabActive].pageNo++;
              that.orderList = that.orderList.concat(res.data.orderVOList);
            }
          })
          .catch(() => {
            that.isLoading = false;
          });
      } else {
        that.isLoading = true;
        that.http
          .get(url, {
            token: that.$store.state.token,
            state: that.tab[that.tabActive].state,
          })
          .then((res) => {
            that.isLoading = false;
            if (res.data.orderVOList && res.data.orderVOList.length > 0) {
              that.orderList = res.data.orderVOList;
            }
          })
          .catch(() => {
            that.isLoading = false;
          });
      }
    },
  },
};
</script>

<style scoped>
.orderListImg {
  width: 20%;
  margin-left: 0.1rem;
}

#canvas-element-id {
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>