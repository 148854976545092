import Vue from 'vue'
import App from './App.vue'
import axios from 'axios'
import VueAxios from 'vue-axios'
import router from './router'
import qs from 'qs'
import http from '@/utils/http'
import permission from '@/utils/permission'
import back from '@/utils/back'
import toPage from '@/utils/toPage'
import redPage from '@/utils/redPage'
import common from '@/utils/common'
import userInfoUpdate from '@/utils/userInfoUpdate'
import previewImg from '@/utils/previewImg'
import envConfig from '@/utils/env'
import store from './store'
import waterfall from 'vue-waterfall2'

// const vconsole = require('vconsole')
// Vue.prototype.$vconsole = new vconsole()

import {
    Button,
    NavBar,
    Toast,
    Swipe,
    SwipeItem,
    Lazyload,
    PullRefresh,
    Image as VanImage,
    Icon,
    Badge,
    Dialog,
    Skeleton,
    Popup,
    Tab,
    Tabs,
    TreeSelect,
    Form,
    Field,
    RadioGroup,
    Radio,
    Stepper,
    Rate,
    Step,
    Steps,
    Empty,
    ActionSheet,
    Area
} from 'vant';
Vue.config.productionTip = false
Vue.use(VueAxios, axios)

import {
    VueJsonp
} from 'vue-jsonp'
Vue.use(VueJsonp)

Vue.prototype.common = common
Vue.prototype.back = back
Vue.prototype.toPage = toPage
Vue.prototype.redPage = redPage
Vue.prototype.userInfoUpdate = userInfoUpdate
Vue.prototype.previewImg = previewImg
Vue.prototype.envConfig = envConfig

Vue.use(NavBar);
Vue.use(Button);
Vue.use(Toast);
Vue.use(Swipe);
Vue.use(SwipeItem);
Vue.use(Lazyload);
Vue.use(PullRefresh);
Vue.use(VanImage);
Vue.use(Icon);
Vue.use(Badge);
Vue.use(Dialog);
Vue.use(Skeleton);
Vue.use(Popup);
Vue.use(Tab);
Vue.use(Tabs);
Vue.use(TreeSelect);
Vue.use(Form);
Vue.use(Field);
Vue.use(RadioGroup);
Vue.use(Radio);
Vue.use(Stepper);
Vue.use(Rate);
Vue.use(Step);
Vue.use(Steps);
Vue.use(Empty);
Vue.use(ActionSheet);
Vue.use(Area);
Vue.use(waterfall);
Vue.prototype.$qs = qs

// Vue.axios.defaults.baseURL = 'apiv2'

Vue.prototype.http = http // 请求函数

// ;
// (function() {
//     var src = '//cdn.jsdelivr.net/npm/eruda';
//     document.write('<scr' + 'ipt src="' + src + '"></scr' + 'ipt>');
//     document.write('<scr' + 'ipt>eruda.init();</scr' + 'ipt>');
// })();

new Vue({
    router,
    permission,
    store,
    render: h => h(App)
}).$mount('#app')