<template>
  <div class="content commonText">
    <van-skeleton :row="8" :loading="pageLoading">
      <van-pull-refresh
        v-model="isLoading"
        @refresh="onRefresh"
        :style="'margin-bottom:' + cartHeight + 'px'"
      >
        <!-- swipper -->
        <div>
          <van-swipe class="my-swipe" :autoplay="3000" indicator-color="white">
            <van-swipe-item v-for="(item, index) in bannerList" :key="index">
              <img :src="item" alt="" class="swipperImg" />
            </van-swipe-item>
          </van-swipe>
        </div>
        <!-- 补价商品 -->
        <div
          class="supplementView"
          style="margin-top: 0.06rem"
          v-if="supplementGoods.id"
        >
          <img
            :src="envConfig().supplementGoodImg"
            alt=""
            class="goodsImg"
            v-lazy="envConfig().supplementGoodImg"
          />
          <div
            class="supplementButton flex aliCen bt"
            @click="addSupplementCart"
          >
            <div style="margin-right: 0.3rem; font-weight: bold">¥ 1</div>
            <div>加入购物车</div>
          </div>
        </div>
        <!-- 分类菜单 -->
        <div class="whiteBgc" style="width: 100%; padding: 0.25rem 0">
          <div class="centerDiv" style="width: 94%">
            <div class="flex aliCen bt">
              <div
                v-for="(item, index) in catMenu"
                :key="index"
                style="width: 23%"
                @click="showGoodsList(item.id)"
              >
                <van-image width="100%" fit="cover" :src="item.pic" />
              </div>
            </div>
            <!-- <div class="maTo20">
              <van-image
                width="100%"
                fit="cover"
                src="https://static.dajingxiapp.com/miniApp/miniGoods.png"
                @click="showGoodsList(158)"
              />
            </div> -->
          </div>
        </div>

        <!-- 商品列表 -->
        <div
          class="centerDiv whiteBgc maTo30"
          style="width: 94%; border-radius: 0.18rem"
          v-if="homeGoodsList.length > 0"
        >
          <div class="centerDiv" style="width: 93%; padding: 0.24rem 0">
            <div class="flex aliCen bt">
              <div class="title">{{ homeGoodsList[0].name }}</div>
              <!-- <div
                class="miniText"
                style="color: #999999"
                @click="openWash(homeGoodsList[0])"
                v-if="homeGoodsList[0].name != '小商城'"
              >
                可洗范围
              </div> -->
              <div
                class="miniText"
                style="color: #999999"
                v-if="homeGoodsList[0].name != '小商城'"
              >
                可洗范围
              </div>
            </div>
            <waterfall
              :col="3"
              :data="homeGoodsList[0].goodsVOList"
              class="maTo30"
            >
              <template v-for="(val, num) in homeGoodsList[0].goodsVOList">
                <div
                  v-if="val.id != envConfig().supplementGoodId"
                  :key="num"
                  class="goodsDiv cflex"
                  @click="toShowGoodsDetail(val)"
                >
                  <div style="width: 93%">
                    <van-image
                      width="100%"
                      height="100"
                      fit="cover"
                      :src="val.picurl"
                      :radius="10"
                    />
                    <!-- <div
                      style="
                        width: 100%;
                        padding-bottom: 100%;
                        background: #111;
                      "
                    ></div> -->
                    <div class="addIcon">
                      <img
                        src="@/images/addicon.png"
                        style="
                          width: 0.45rem;
                          height: 0.45rem;
                          margin-right: 0.2rem;
                          z-index: 1;
                          border-radius: 50%;
                        "
                        @click.stop="addCartList($event, val)"
                      />
                    </div>
                    <div style="padding: 0.25rem 0 0.4rem 0">
                      <div class="goodsName divText">
                        {{ val.name }}
                      </div>
                      <div class="flex aliCen maTo30">
                        <div
                          class="orange"
                          style="font-size: 0.3rem; width: 50%"
                        >
                          ￥{{ val.vipPrice }}
                        </div>
                        <div
                          class="miniText"
                          style="text-decoration: line-through"
                        >
                          ￥{{ val.price }}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </template>
            </waterfall>
          </div>
        </div>
      </van-pull-refresh>
    </van-skeleton>

    <!-- shopcart -->
    <div class="popup">
      <div v-show="isShowShopCart" class="showCartDiv">
        <div class="cartHead">
          <div
            class="centerDiv pflex"
            style="justify-content: space-between; width: 95%"
          >
            <div class="orange">
              已为您节省:
              <span style="margin-left: 0.05rem"
                >¥{{ fixed(preferentialAmount) }}</span
              >
            </div>
            <div @click="clearCart">清空购物车</div>
          </div>
        </div>
        <!-- goodsList -->
        <div ref="wrapper" class="wrapperDiv">
          <div class="content" v-if="cartList.length > 0">
            <div
              v-for="(item, index) in cartList"
              :key="index"
              class="cartGoodsDiv"
            >
              <div class="pflex centerDiv">
                <div class="leftImg">
                  <img
                    :src="item.picurl"
                    alt=""
                    v-if="item.id != envConfig().supplementGoodId"
                  />
                  <img :src="envConfig().supplementGoodImg" alt="" v-else />
                </div>
                <div class="middleInfo cflex">
                  <div style="line-height: 1.5em">{{ item.name }}</div>
                  <div class="orange maTo20">¥{{ item.vipPrice }}</div>
                </div>
                <div class="rightNum pflex bt">
                  <div class="pflex">
                    <div class="add pflex" @click="minusCartList(item.id)">
                      <img src="@/images/jian.png" style="width: 100%" alt="" />
                    </div>
                  </div>
                  <div>{{ item.goodsNum }}</div>
                  <div class="pflex">
                    <div class="add pflex" @click="addCart(item)">
                      <img src="@/images/jia.png" style="width: 100%" alt="" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="content" v-else>
            <van-empty />
          </div>
        </div>
      </div>

      <div class="cartDiv" ref="cartDiv" @click="openShopCart">
        <div
          class="centerDiv pflex"
          style="justify-content: space-between; padding-bottom: 0.25rem"
        >
          <div ref="cartIcon">
            <van-badge :content="goodsSum">
              <van-icon name="shopping-cart-o" size="0.6rem" color="#8f8f8f" />
            </van-badge>
          </div>
          <div class="pflex">
            <!-- <div style="margin-right: 0.35rem" v-if="cartList.length > 0">
              <div class="miniText">
                <span class="miniText">已节省：</span>
                <span class="orange">￥{{ fixed(preferentialAmount) }}</span>
              </div>
            </div> -->

            <div
              style="margin-right: 0.5rem; align-items: flex-end"
              class="cflex"
            >
              <div class="orange">
                <span>¥ </span
                ><span class="totalPrice"> {{ fixed(totalAmount) }}</span>
              </div>
              <div class="miniText maTo10">
                <span class="miniText">已为您节省：</span>
                <span class="orange">￥{{ fixed(preferentialAmount) }}</span>
              </div>
            </div>
            <div>
              <div class="orderButton mainBgc" @click.stop="toOrder">
                去下单
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div
      class="over"
      v-if="isShowShopCart"
      @click="closeCart"
      @touchmove.prevent
    ></div>

    <flyInCart ref="inCart" :cartBasketRect="cartBasketRect"></flyInCart>

    <div class="couponPopup cflex" v-if="showCoupon">
      <div class="cflex couponPopup1">
        <div>您有红包可以使用</div>
        <div class="centerDiv" style="padding: 0.3rem">
          <div
            class="maTo20 pflex bt"
            v-for="(item, index) in homeInfo.memberCouponVOList"
            :key="index"
          >
            <div class="couponDiv pflex">
              <div
                class="cflex"
                style="width: 30%"
                :class="item.isReuse == 1 ? 'mainColor' : 'orange'"
              >
                <div>
                  <span>¥</span>
                  <span style="font-size: 0.45rem; margin-left: 0.15rem">{{
                    item.amount
                  }}</span>
                </div>
                <div class="maTo20" style="font-size: 0.23rem">
                  <span v-if="item.limitAmount > 0"
                    >满{{ item.limitAmount }}可用</span
                  >
                  <span v-else>无门槛红包</span>
                </div>
              </div>
              <div style="width: 50%">
                <div>{{ item.name }}</div>
                <div class="miniText maTo20">有效期至{{ item.endTime }}</div>
              </div>
              <div
                style="width: 20%; font-size: 0.23rem"
                class="useButton mainColor pflex"
                v-if="item.isReuse == 1"
              >
                同享券
              </div>

              <div
                style="width: 20%; font-size: 0.23rem"
                class="useButton pflex orange"
                v-else
              >
                互斥券
              </div>
            </div>
          </div>
        </div>
      </div>
      <van-icon
        name="close"
        size="30"
        color="#ffffff"
        style="margin-top: 0.3rem"
        @click="closeCoupon"
      />
    </div>
    <div
      class="over"
      @click="closeCoupon"
      style="z-index: 9999"
      v-if="showCoupon"
    ></div>
    <!-- <floatButton @onFloatBtnClicked="onFloatBtnClicked"></floatButton> -->
    <!-- 补款数量 -->
    <van-dialog
      v-model="showSuppPop"
      title="补款金额"
      show-cancel-button
      @confirm="addSupplement"
    >
      <div class="pflex" style="margin: 0.4rem 0">
        <van-stepper v-model="suppleVal" min="1" step="1" input-width="100px" />
      </div>
    </van-dialog>

    <!-- 商品详情 -->
    <van-dialog
      v-model="showDetail"
      title="详情"
      show-cancel-button
      confirmButtonText="加入购物车"
      @confirm="addCart(showGoodsDetail)"
    >
      <van-image width="100%" fit="cover" :src="showGoodsDetail.picurl" />
      <div
        class="centerDiv title maTo30 pflex"
        style="font-size: 0.34rem; margin-bottom: 0.35rem; line-height: 1.5em"
      >
        {{ showGoodsDetail.name }}
      </div>
    </van-dialog>
  </div>
</template>

<script>
import BScroll from "better-scroll";
import flyInCart from "./flyCart";
import CryptoJS from "crypto-js";
// import floatButton from "@/components/floatButton";
export default {
  data() {
    return {
      homeInfo: Object,
      isLoading: false,
      cartHeight: 0,
      pageLoading: true,
      isShowShopCart: false,
      cartList: [],
      totalAmount: 0, // 总金额
      preferentialAmount: 0, // 优惠金额
      goodsSum: 0, // 商品总数
      cartBasketRect: {},
      showCoupon: false,
      supplementGoods: {}, // 补价商品
      showSuppPop: false,
      suppleVal: 1,
      bannerList: [
        "https://static.dajingxiapp.com/miniApp/%E7%BE%8E%E5%AE%B6%E6%B4%81banner-02.jpg",
        "https://static.dajingxiapp.com/miniApp/%E7%BE%8E%E5%AE%B6%E6%B4%81banner-03.jpg",
        "https://static.dajingxiapp.com/miniApp/%E7%BE%8E%E5%AE%B6%E6%B4%81banner-04.jpg",
      ],
      catMenu: [
        {
          id: 157,
          pic: "https://static.dajingxiapp.com/miniApp/%E7%BC%96%E7%BB%84%206.png",
        },
        {
          id: 167,
          pic: "https://static.dajingxiapp.com/miniApp/meijiajiexixie.png",
        },
        {
          id: 158,
          pic: "https://static.dajingxiapp.com/miniApp/washarea.jpg",
        },
        {
          id: 168,
          pic: "https://static.dajingxiapp.com/miniApp/%E7%BC%96%E7%BB%84%209.png",
        },
      ],
      homeGoodsList: [],
      showDetail: false,
      showGoodsDetail: {},
      code: "",
    };
  },
  mounted() {
    const that = this;
    let queryParam = that.getParam() || {};
    if (queryParam && queryParam.code) {
      let stateList = [];
      if (queryParam.state) {
        stateList = queryParam.state.split(",");
      }
      if (queryParam.citicuserlogin != 1) {
        that.http
          .get(
            `https://rain.mjj.dajingxiapp.cn/api/wecom/user/${stateList[0]}/${stateList[1]}/${queryParam.code}`
          )
          .then((res) => {
            if (res.data.stateVO.code == 200) {
              that.http
                .get("mjj/login.json", {
                  token: res.data.user.userid,
                })
                .then((val) => {
                  that.getInfo();
                  if (val.data.stateVO.code == 200) {
                    that.userInfoUpdate(val.data.memberVO);
                    if (stateList.length == 3) {
                      that.toPage("pay", { orderId: stateList[2] });
                    } else {
                      location.replace(
                        location.href.substring(0, location.href.indexOf("?"))
                      );
                    }
                  } else {
                    that.$toast(val.data.stateVO.msg);
                  }
                })
                .catch(() => {
                  that.$toast("登录失败，请尝试重新进入或联系技术人员");
                  that.getInfo();
                });
            } else {
              that.$toast("登录失败，请尝试重新进入或联系技术人员");
              that.getInfo();
            }
          })
          .catch(() => {
            that.$toast("登录失败，请尝试重新进入或联系技术人员");
            that.getInfo();
          });
      } else {
        console.log(1234);
        that.http
          .get("mjj/citicUserLogin.json", {
            token: queryParam.code,
          })
          .then((val) => {
            that.getInfo();
            if (val.data.stateVO.code == 200) {
              that.userInfoUpdate(val.data.memberVO);
              if (stateList.length == 3) {
                that.toPage("pay", { orderId: stateList[2] });
              } else {
                location.replace(
                  location.href.substring(0, location.href.indexOf("?"))
                );
              }
            } else {
              that.$toast(val.data.stateVO.msg);
            }
          })
          .catch(() => {
            that.$toast("登录失败，请尝试重新进入或联系技术人员");
            that.getInfo();
          });
      }
    } else {
      // that.$toast("请返回企业微信重试");
      that.getInfo();
    }
  },
  methods: {
    fixed(num) {
      let res = 0;
      if (typeof num == "number") {
        res = num.toFixed(2);
      }
      return res;
    },
    getParam() {
      // var url = window.location.href;
      // var dz_url = url.split("#")[0];
      // console.log(dz_url);
      // var cs = dz_url.split("?")[1];
      // var csa = {};
      // if (cs) {
      //   var cs_arr = cs.split("&");
      //   for (var i = 0; i < cs_arr.length; i++) {
      //     csa[cs_arr[i].split("=")[0]] = cs_arr[i].split("=")[1];
      //   }
      // }
      // return csa;
      let csa = {};
      csa = this.$route.query;
      console.log("csa", csa);
      if (Object.keys(csa).length == 0) {
        console.log(111);
        var url = window.location.href;
        var dz_url = url.split("#")[0];
        var cs = dz_url.split("?")[1];
        if (cs) {
          var cs_arr = cs.split("&");
          for (var i = 0; i < cs_arr.length; i++) {
            csa[cs_arr[i].split("=")[0]] = cs_arr[i].split("=")[1];
          }
        }
        console.log("csa", csa);
      }
      return csa;
    },
    toShowGoodsDetail(item) {
      const that = this;
      that.showDetail = true;
      that.showGoodsDetail = item;
    },
    // 展示对应商品列表
    showGoodsList(id) {
      const that = this;
      console.log(id);
      if (
        that.homeInfo.categoryVOList &&
        that.homeInfo.categoryVOList.length > 0
      ) {
        that.homeGoodsList = that.homeInfo.categoryVOList.filter(function (
          value
        ) {
          return value.id == id;
        });
      }
    },
    onFloatBtnClicked() {
      let url = "https://weixin.dajingxiapp.com/static/web/ce.html";
      if (this.$store.state.userInfo.id) {
        url =
          "https://weixin.dajingxiapp.com/static/web/ce.html?uid=" +
          this.$store.state.userInfo.id +
          "&name=【美家洁用户】" +
          this.$store.state.userInfo.name +
          "&pic=" +
          this.$store.state.userInfo.avatar;
      }
      // console.log(url)
      window.location.href = url;
    },
    getInfo() {
      const that = this;
      let cartBasketRect = that.$refs.cartIcon.getBoundingClientRect();
      let posObj = {};
      for (let i in cartBasketRect) {
        posObj[i] = cartBasketRect[i];
      }
      that.cartBasketRect = posObj;
      that.cartHeight = that.$refs.cartDiv.offsetHeight + 40;
      that.http
        .get("index/home.json", {
          token: that.$store.state.token,
          platform: "zhongxin",
        })
        .then((res) => {
          that.homeInfo = res.data;
          if (that.envConfig().env == "save") {
            let deleteIndex = -1;
            for (let j in that.homeInfo.activityVOList) {
              if (that.homeInfo.activityVOList[j].id == 2) {
                deleteIndex = j;
                break;
              }
            }
            if (deleteIndex >= 0) {
              that.homeInfo.activityVOList.splice(deleteIndex, 1);
            }
          }
          for (let i of that.homeInfo.categoryVOList[0].goodsVOList) {
            if (i.id == that.envConfig().supplementGoodId) {
              that.supplementGoods = i;
              break;
            }
          }
          // let t = {};
          // t = that.homeInfo.categoryVOList[1];
          // that.homeInfo.categoryVOList[1] = that.homeInfo.categoryVOList[2];
          // that.homeInfo.categoryVOList[2] = t;
          that.cartList = res.data.cartVOList;
          that.showGoodsList(157);
          that.$forceUpdate();
          if (that.homeInfo.memberCouponVOList.length > 0) {
            if (that.$store.state.indexShowCoupon) {
              that.showCoupon = true;
              this.$store.commit("indexShowCouponUpdate", false);
            }
          } else {
            that.showCoupon = false;
          }
          that.goodsCount();
          that.pageLoading = false;
        })
        .catch((err) => {
          that.$toast(err);
        });
    },
    isEmojiCharacter(substring) {
      for (var i = 0; i < substring.length; i++) {
        var hs = substring.charCodeAt(i);
        var ls = substring.charCodeAt(i + 1);
        if (0xd800 <= hs && hs <= 0xdbff) {
          if (substring.length > 1) {
            var uc = (hs - 0xd800) * 0x400 + (ls - 0xdc00) + 0x10000;
            if (0x1d000 <= uc && uc <= 0x1f77f) {
              return true;
            }
          }
        } else if (substring.length > 1) {
          if (ls == 0x20e3) {
            return true;
          }
        } else {
          if (0x2100 <= hs && hs <= 0x27ff) {
            return true;
          } else if (0x2b05 <= hs && hs <= 0x2b07) {
            return true;
          } else if (0x2934 <= hs && hs <= 0x2935) {
            return true;
          } else if (0x3297 <= hs && hs <= 0x3299) {
            return true;
          } else if (
            hs == 0xa9 ||
            hs == 0xae ||
            hs == 0x303d ||
            hs == 0x3030 ||
            hs == 0x2b55 ||
            hs == 0x2b1c ||
            hs == 0x2b1b ||
            hs == 0x2b50
          ) {
            return true;
          }
        }
      }
    },
    hexToBase64(str) {
      return btoa(
        String.fromCharCode.apply(
          null,
          str
            .replace(/\r|\n/g, "")
            .replace(/([\da-fA-F]{2}) ?/g, "0x$1 ")
            .replace(/ +$/, "")
            .split(" ")
        )
      );
    },
    base64ToHex(str) {
      for (
        var i = 0, bin = atob(str.replace(/[ \r\n]+$/, "")), hex = [];
        i < bin.length;
        ++i
      ) {
        var tmp = bin.charCodeAt(i).toString(16);
        if (tmp.length === 1) tmp = "0" + tmp;
        hex[hex.length] = tmp;
      }
      return hex.join(" ");
    },
    // 加密
    encryptByDES(message, key, iv) {
      var keyHex = CryptoJS.enc.Utf8.parse(key);
      var ivHex = CryptoJS.enc.Utf8.parse(iv);
      let encrypted = CryptoJS.DES.encrypt(message, keyHex, {
        iv: ivHex,
        mode: CryptoJS.mode.CBC,
        padding: CryptoJS.pad.Pkcs7,
      });
      return encrypted.ciphertext.toString();
    },
    // 解密
    decrypt(ciphertext, key, iv) {
      //把私钥转换成UTF - 8编码的字符串
      var keyHex = CryptoJS.enc.Utf8.parse(key);
      var ivHex = CryptoJS.enc.Utf8.parse(iv);
      var decrypted = CryptoJS.DES.decrypt(
        {
          ciphertext: CryptoJS.enc.Hex.parse(ciphertext),
        },
        keyHex,
        {
          iv: ivHex,
          mode: CryptoJS.mode.CBC,
          padding: CryptoJS.pad.Pkcs7,
        }
      );
      return decrypted.toString(CryptoJS.enc.Utf8);
    },
    addCart(item) {
      const that = this;
      function add() {
        that.http
          .get("cart/add.json", {
            goodsIds: item.id + ":1",
            token: that.$store.state.token,
          })
          .then((res) => {
            that.cartList = res.data.cartVOList;
            that.goodsCount();
          });
      }
      let flag = localStorage.getItem("userInfo");
      if (!flag || flag == "undefined" || flag == undefined) {
        that.$toast("登录失败，请尝试重新进入或联系技术人员");
        // that.toPage("login");
      } else {
        flag = JSON.parse(flag);
        if (flag.token) {
          if (that.cartList.length > 0) {
            if (that.cartList[0].isNotMail != item.isNotMail) {
              that.$toast("不同品类商品不可同时下单");
            } else {
              add();
            }
          } else {
            add();
          }
        } else {
          // that.toPage("login");
          that.$toast("登录失败，请尝试重新进入或联系技术人员");
        }
      }
    },
    // 补款商品加入购物车
    addSupplementCart() {
      const that = this;
      that.showSuppPop = !that.showSuppPop;
    },
    addCartList(e, val) {
      const that = this;
      let flag = localStorage.getItem("userInfo");
      if (!flag || flag == "undefined" || flag == undefined) {
        that.$toast("登录失败，请尝试重新进入或联系技术人员");
        // that.toPage("login");
      } else {
        flag = JSON.parse(flag);
        if (flag.token) {
          //  that.toAdd(e, val);
          // 加入购物车前置判断
          if (that.cartList.length > 0) {
            if (that.cartList[0].isNotMail != val.isNotMail) {
              that.$toast("不同品类商品不可同时下单");
            } else {
              that.toAdd(e, val);
            }
          } else {
            that.toAdd(e, val);
          }
        } else {
          that.$toast("登录失败，请尝试重新进入或联系技术人员");
          // that.toPage("login");
        }
      }
    },
    toAdd(e, val) {
      const that = this;
      that.$refs.inCart.addToCart(e, val.id);
      that.http
        .get("cart/add.json", {
          goodsIds: val.id + ":1",
          token: that.$store.state.token,
        })
        .then((res) => {
          that.cartList = res.data.cartVOList;
          that.goodsCount();
        });
    },
    addSupplement() {
      const that = this;
      let flag = localStorage.getItem("userInfo");
      if (!flag || flag == "undefined" || flag == undefined) {
        that.$toast("登录失败，请尝试重新进入或联系技术人员");
        // that.toPage("login");
      } else {
        flag = JSON.parse(flag);
        if (flag.token) {
          if (that.suppleVal > 0) {
            that.http
              .get("cart/add.json", {
                goodsIds:
                  that.envConfig().supplementGoodId + ":" + that.suppleVal,
                token: that.$store.state.token,
              })
              .then((res) => {
                that.cartList = res.data.cartVOList;
                that.$toast("已加入购物车");
                that.goodsCount();
              });
          } else {
            that.$toast("补款金额为0");
          }
        } else {
          that.$toast("登录失败，请尝试重新进入或联系技术人员");
          // that.toPage("login");
        }
      }
    },
    minusCartList(id) {
      const that = this;
      that.http
        .get("cart/delete.json", {
          goodsIds: id,
          token: that.$store.state.token,
        })
        .then((res) => {
          that.cartList = res.data.cartVOList;
          that.goodsCount();
        });
    },
    onRefresh() {
      const that = this;
      that.http
        .get("index/home.json", {
          token: that.$store.state.token,
          platform: "zhongxin",
        })
        .then((res) => {
          // that.homeInfo = res.data;
          that.cartList = res.data.cartVOList;
          that.goodsCount();
          that.isLoading = false;
        });
    },
    openWash(item) {
      const that = this;
      that.$dialog.alert({
        title: item.title,
        message: item.content,
        confirmButtonColor: that.common.mainColor,
      });
    },
    openShopCart() {
      // 打开购物车
      this.isShowShopCart = true;
      setTimeout(() => {
        this.initScroll();
      }, 20);
    },
    closeCart() {
      this.isShowShopCart = false;
    },
    initScroll() {
      this.$nextTick(() => {
        if (!this.Scroll) {
          this.Scroll = new BScroll(this.$refs.wrapper, {
            click: true, // 配置允许点击事件
            scrollY: true, // 开启纵向滚动
          });
        } else {
          this.Scroll.refresh(); // 重新计算 better-scroll，当 DOM 结构发生变化的时确保滚动效果正常
        }
      });
    },
    clearCart() {
      const that = this;
      that.$dialog
        .confirm({
          title: "提示",
          message: "清空购物车？",
          confirmButtonColor: that.common.mainColor,
        })
        .then(() => {
          // on confirm
          that.http
            .get("cart/delete.json", {
              goodsIds: 0,
              token: that.$store.state.token,
            })
            .then((res) => {
              that.cartList = res.data.cartVOList;
              that.goodsCount();
            });
        })
        .catch(() => {
          // on cancel
        });
    },
    closeCoupon() {
      this.showCoupon = false;
    },
    goodsCount() {
      const that = this;
      that.totalAmount = 0;
      that.preferentialAmount = 0;
      that.goodsSum = 0;
      for (let i of that.cartList) {
        that.totalAmount = that.totalAmount + i.goodsNum * i.vipPrice;
        that.goodsSum = that.goodsSum + i.goodsNum;
        that.preferentialAmount =
          that.preferentialAmount + (i.price - i.vipPrice) * i.goodsNum;
      }
      if (that.totalAmount >= that.$store.state.userInfo.expenseCondition) {
        that.needExpense = false;
      } else {
        that.needExpense = true;
      }
    },
    toOrder() {
      this.isShowShopCart = false;
      // document.removeEventListener("touchmove", preD, { passive: false });
      if (this.goodsSum > 0) {
        this.toPage("order");
      } else {
        this.$dialog.alert({
          title: "提示",
          message: "您的购物车中还没有商品呢",
          confirmButtonColor: this.common.mainColor,
        });
      }
    },
  },
  watch: {
    // 监听data中弹层状态
    // isShowShopCart(val) {
    //   if (val) {
    //     document.body.style.overflow = "hidden";
    //     document.addEventListener("touchmove", preD, { passive: false }); // 禁止页面滑动
    //   } else {
    //     document.removeEventListener("touchmove", preD, { passive: false });
    //   }
    // },
  },
  components: {
    flyInCart,
    // floatButton,
  },
};
</script>

<style scoped>
/* .divText {
white-space:nowrap;
text-overflow:ellipsis;
-o-text-overflow:ellipsis;
overflow: hidden;
} */
.swipperImg {
  width: 100%;
}

.titleDiv {
  justify-content: space-between;
  padding: 0.3rem 0;
}

.title {
  font-size: 0.3rem;
  font-weight: bold;
}

.goodsImg {
  width: 100%;
}

.goodsName {
  font-size: 0.25rem;
  color: rgb(87, 87, 87);
  width: 95%;
  margin: 0 auto;
  line-height: 1.5em;
  /* height: 0.8rem; */
  font-weight: 600;
  text-align: center;
  overflow: hidden; /*对超出容器的部分强制截取，高度不确定则换行*/
  text-overflow: ellipsis; /*显示省略符号来代表被修剪的文本。*/
  white-space: nowrap; /*禁止换行*/
}

.cartDiv {
  width: 100%;
  background: #ffffff;
  padding: 0.2rem 0;
  padding-bottom: env(safe-area-inset-bottom);
  border-top: #ebe6e6 solid 0.02rem;
  /* z-index: 99999; */
}

.price {
  font-size: 0.4rem;
}

.addIcon {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  position: relative;
  top: -0.7rem;
  right: 0;
  margin-bottom: -0.5rem;
}

.addressButton {
  background: #ffffff;
  padding: 0.15rem 0.3rem;
  position: absolute;
  top: 0.2rem;
  left: 0.2rem;
  border-radius: 0.3rem;
}

.addressButton > img {
  width: 0.2rem;
  margin-right: 0.1rem;
}

.orderButton {
  color: #ffffff;
  padding: 0.22rem 0.3rem;
  border-radius: 0.1rem;
}

.totalPrice {
  font-size: 0.4rem;
  font-weight: 500;
}

.cartHead {
  width: 100%;
  background: #f1eeee;
  padding: 0.2rem 0;
  border-top-left-radius: 0.15rem;
  border-top-right-radius: 0.15rem;
}

.miniText {
  color: rgb(107, 107, 107);
}

.wrapperDiv {
  height: 300px;
  width: 100%;
  overflow: hidden;
  background: #ffffff;
}

.popup {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  z-index: 999;
}

.over {
  position: fixed;
  width: 100%;
  height: 100%;
  opacity: 0.7;
  filter: alpha(opacity=70);
  top: 0;
  left: 0;
  z-index: 1;
  background-color: #111111;
}

.leftImg {
  width: 20%;
}

.leftImg > img {
  width: 100%;
}

.middleInfo {
  width: 60%;
  align-items: flex-start;
  padding-left: 0.2rem;
}
.rightNum {
  width: 20%;
}

.cartGoodsDiv {
  padding: 0.15rem 0;
}

@keyframes show {
  0% {
    transform: translate(0, 150%);
  }

  100% {
    transform: translate(0, 0);
  }
}

/* .showCartDiv { */
/* animation: show 0.5s linear forwards; */
/* } */

.add {
  width: 0.4rem;
  height: 0.4rem;
  /* border-radius: 50%; */
  color: white;
  font-size: 0.2rem;
}

.minus {
  width: 0.41rem;
  height: 0.41rem;
  border-radius: 50%;
  /* font-size: 0.2rem; */
}

.couponPopup {
  position: fixed;
  font-size: 0.25rem;
  /* padding: 0.4rem 0; */
  /* height: 60%; */
  width: 90%;
  /* background-color: white; */
  left: 50%;
  top: 50%;
  display: flex;
  flex-direction: column;
  align-content: center;
  transform: translate(-50%, -50%);
  z-index: 100000;
}

.couponPopup1 {
  width: 100%;
  border-radius: 0.15rem;
  background: white;
  padding: 0.4rem 0;
}

.couponDiv {
  width: 100%;
  background: white;
  padding: 0.4rem 0;
  /* border:#e9e9e9 solid 0.01rem */
  box-shadow: 0px 0.05rem 0.13rem #d8d8d8;
}
.supplementView {
  position: relative;
}
.supplementButton {
  position: absolute;
  color: #ffffff;
  padding: 0.15rem 0.2rem;
  background: rgba(243, 52, 52, 0.651);
  /* border: solid 0.02rem #ffffff; */
  border-radius: 0.1rem;
  right: 10%;
  bottom: 0.3rem;
}

@media only screen and (min-device-width: 320px) {
  .goodsDiv {
    /* width: 25%; */
    /* background: #ededed; */
    margin-top: 0.05rem;
  }
}

@media only screen and (max-device-width: 320px) {
  .goodsDiv {
    /* width: 33.3%; */
    /* background: #ededed; */
    margin-top: 0.05rem;
  }
}
</style>