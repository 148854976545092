import { ImagePreview } from "vant";

function previewImg(imgList, index) {
    let imgIndex = 0
    if (index) {
        imgIndex = index
    }
    ImagePreview({
        images: imgList,
        startPosition: imgIndex,
    });
}
export default previewImg;