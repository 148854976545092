<template>
  <div class="content commonText">
    <!-- <van-nav-bar title="评价" left-text="返回" left-arrow @click-left="back" /> -->
    <!-- evaluate -->
    <div class="cflex" style="padding-top: 0.7rem">
      <div class="pflex">
        <div style="margin-right: 0.2rem">洗护质量</div>
        <van-rate
          v-model="star1"
          :size="25"
          color="#ffd21e"
          void-icon="star"
          void-color="#eee"
        />
      </div>

      <div class="pflex maTo30">
        <div style="margin-right: 0.2rem">服务态度</div>
        <van-rate
          v-model="star2"
          :size="25"
          color="#ffd21e"
          void-icon="star"
          void-color="#eee"
        />
      </div>
    </div>

    <div class="maTo30 centerDiv">
      <van-field
        v-model="remark"
        rows="5"
        autosize
        label="评价"
        type="textarea"
        maxlength="250"
        placeholder="请输入留言"
        show-word-limit
      />
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      star1: 5,
      star2: 5,
      remark: "",
    };
  },
};
</script>