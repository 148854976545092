<template>
  <div class="content">
    <!-- 顶部导航栏 -->
    <div class="head" v-if="showTab">
      <div class="tabbar pflex">
        <div
          class="tab pflex"
          v-for="(item, index) in tab"
          :key="index"
          :class="index == indextab ? 'choosetab mainColor' : 'commonText'"
          @click="choosetab(index)"
        >
          <div
            class="pflex"
            style="
              flex-direction: column;
              justify-content: space-around;
              padding: 0.1rem 0;
              height: 100%;
            "
          >
            <div style="height: 50%">{{ item.tab }}</div>
          </div>
          <div v-if="index == indextab" class="chooseline mainBgc"></div>
          <div v-if="index !== indextab" class="notchooseline"></div>
        </div>
      </div>
    </div>

    <div v-if="!showTab && showNav" ref="navvar" class="navvar" id="navbar">
      <van-nav-bar
        :title="routeName"
        left-text="返回"
        left-arrow
        @click-left="back"
      />
    </div>

    <div :class="showTab ? 'routerView' : ''" :style="showTab ? '' : height">
      <keep-alive>
        <router-view v-if="$route.meta.keepAlive"> </router-view>
      </keep-alive>

      <router-view v-if="!$route.meta.keepAlive"> </router-view>
    </div>

    <!-- 客服 -->
    <!-- <div class="kefuTelDiv" @click="toTel" v-if="showCe">
      <img
        src="@/images/kefu2.png"
        alt=""
        style="width: 0.8rem; height: 0.8rem"
      />
    </div> -->

    <dragBallComponent needNearEdge >
    </dragBallComponent>
  </div>
</template>

<script>
import dragBallComponent from "@/components/dragBallComponent.vue";
export default {
  name: "App",
  data() {
    return {
      tab: [
        {
          tab: "首页",
          index: 0,
          path: "/",
        },
        {
          tab: "订单",
          index: 1,
          path: "/orderList",
        },
        {
          tab: "我的",
          index: 2,
          path: "/user",
        },
      ],
      indextab: 0, //默认tab
      showTab: false,
      routeName: "",
      height: "",
      code: "",
      showNav: true,
      showCe: true,
    };
  },
  components: {
    dragBallComponent,
  },
  methods: {
    setNavColor(color) {
      if (!this.showTab && this.showNav) {
        this.$nextTick(() => {
          let navtext = document.getElementsByClassName("van-nav-bar__text")[0];
          let arrow = document.getElementsByClassName("van-icon-arrow-left")[0];
          if (navtext) navtext.style.color = color || "#1aad19";
          if (arrow) {
            if (color) {
              arrow.style.display = "none";
            } else {
              arrow.style.display = "block";
            }
          }
        });
      }
    },
    toTel() {
      window.location.href = "tel://17302671259";
    },
    choosetab(index) {
      const that = this;
      // that.indextab = index;
      that.$router.replace({ path: that.tab[index].path });
    },
  },
  created() {
    const that = this;
    let userInfo = localStorage.getItem("userInfo");
    if (userInfo && userInfo !== "undefined" && userInfo !== undefined) {
      let userInfoObj = JSON.parse(userInfo);
      if (userInfoObj.token) {
        that.userInfoUpdate(userInfoObj);
      }
    }
  },
  mounted() {
    let path = this.$route.path;
    this.routeName = this.$route.meta.cName;
    let userInfo = localStorage.getItem("userInfo");
    if (userInfo && userInfo !== "undefined" && userInfo !== undefined) {
      let userInfoObj = JSON.parse(userInfo);
      if (userInfoObj.token) {
        this.userInfoUpdate(userInfoObj);
      }
    }
    if (path == "/" || path == "/orderList" || path == "/user") {
      this.showTab = true;
    }
    if (this.$route.meta.title) {
      //判断是否有标题
      document.title = this.$route.meta.title;
    } else {
      document.title = "美家洁";
    }
    if (this.$route.meta.noNav) {
      this.showNav = false;
    } else {
      this.showNav = true;
    }
    if (this.$route.meta.noCe) {
      this.showCe = false;
    } else {
      this.showCe = true;
    }
    for (let i of this.tab) {
      if (i.path == path) {
        this.indextab = i.index;
        break;
      }
    }
    let navHeight = "";
    if (this.showNav) {
      this.height = "margin-top:" + this.$refs.navvar.offsetHeight + "px";
      navHeight = this.$refs.navvar.offsetHeight + "px";
    } else {
      this.height = "margin-top:0px";
      navHeight = "0px";
    }
    this.$store.commit("navHeightUpdate", navHeight);
    if (this.$route.meta.nav) {
      this.setNavColor(this.$route.meta.nav);
    } else {
      this.setNavColor();
    }
  },
  watch: {
    $route(to) {
      const that = this;
      window.scrollTo(0, 0);
      if (to.meta.title) {
        //判断是否有标题
        document.title = to.meta.title;
      } else {
        document.title = "美家洁";
      }
      if (to.meta.cName) {
        that.routeName = to.meta.cName;
        if (to.params.type && to.params.type == 1) {
          that.showNav = false;
        } else that.showNav = true;
      }
      if (to.meta.noNav) {
        this.showNav = false;
      } else {
        this.showNav = true;
      }
      if (to.meta.noCe) {
        this.showCe = false;
      } else {
        this.showCe = true;
      }
      //判断是否显示tabbar
      if (to.name == "index" || to.name == "orderList" || to.name == "user") {
        that.showTab = true;
      } else {
        that.showTab = false;
        setTimeout(() => {
          let navHeight = "";
          if (that.showNav) {
            that.height = "margin-top:" + that.$refs.navvar.offsetHeight + "px";
            navHeight = that.$refs.navvar.offsetHeight + "px";
          } else {
            that.height = "margin-top:0px";
            navHeight = "0px";
          }
          that.$store.commit("navHeightUpdate", navHeight);
        });
      }
      for (let i of this.tab) {
        if (i.path == to.path) {
          that.indextab = i.index;
          break;
        }
      }
      if (to.meta.nav) {
        this.setNavColor(to.meta.nav);
      } else {
        this.setNavColor();
      }
    },
  },
};
</script>

<style scoped>
.kefuTelDiv {
  background: #73c3ce;
  position: fixed;
  right: 5%;
  bottom: 15%;
  padding: 0.2rem;
  border-radius: 50%;
  box-shadow: 0px 10px 20px -10px #515151;
  z-index: 999;
}
.head {
  width: 100%;
  background: #ffffff;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
}

.tabbar {
  width: 100%;
  color: #636363;
  font-size: 0.28rem;
  height: 1.07rem;
  justify-content: space-around;
}

.tab {
  width: 70%;
  height: 100%;
  flex-direction: column;
  justify-content: space-between;
}

.choosetab {
  /* font-size: 0.36rem; */
  font-weight: bold;
}

.chooseline {
  width: 70%;
  height: 0.05rem;
}

.notchooseline {
  width: 70%;
  height: 0.05rem;
}

.routerView {
  margin-top: 1.11rem;
}

.navvar {
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9999;
}
</style>
