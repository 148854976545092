<template>
  <!-- position:fixed -->
  <div class="content commonText">
    <orderAddress :addressList="addressList"></orderAddress>
    <timeDrawer
      ref="timeChoose"
      @getChooseTime="getTime"
      v-if="cartList[0] && cartList[0].isNotMail !== 1"
    ></timeDrawer>
    <div>
      <van-field
        v-model="remark"
        rows="3"
        autosize
        label="留言"
        type="textarea"
        maxlength="250"
        placeholder="请输入留言(快递小哥看不到哦)"
        show-word-limit
      />
    </div>

    <titleModule :title="'订单信息'" class="maTo20" :slotWidth="'100'">
      <div
        v-for="(item, index) in cartList"
        :key="index"
        class="cartGoodsDiv"
        style="border-bottom: #f5f1f1 solid 0.01rem"
      >
        <div class="pflex centerDiv">
          <div class="leftImg pflex">
            <img :src="item.picurl" alt="" />
          </div>
          <div class="middleInfo cflex">
            <div style="line-height: 1.5em">{{ item.name }}</div>
            <div class="orange maTo20">¥{{ item.vipPrice }}</div>
          </div>
          <div class="rightNum">数量 x {{ item.goodsNum }}</div>
        </div>
      </div>
    </titleModule>

    <titleModule
      :title="'门店包裹'"
      class="maTo20"
      :slotWidth="'100'"
      v-if="$store.state.userInfo.isSteward"
    >
      <div class="centerDiv">
        <van-field
          v-model="packageCode"
          center
          clearable
          maxlength="15"
          label="包裹码："
          style="padding: 0"
          :formatter="formatter"
          placeholder="请输入包裹码"
        >
          <template #button>
            <van-button
              size="small"
              type="primary"
              color="#1aad19"
              v-if="!scanning"
              @click="startScanning"
              >扫描条形码</van-button
            >
            <van-button
              size="small"
              color="#1aad19"
              type="primary"
              v-else
              @click="clickIndexLeft"
              >关闭</van-button
            >
          </template>
        </van-field>
        <div class="maTo20 miniText orange">
          已输入{{ packageCode.length }}/15
        </div>
      </div>
    </titleModule>

    <titleModule :title="'运费'" class="maTo20" :slotWidth="'100'">
      <div class="flex aliCen bt centerDiv">
        <!-- <div v-if="cartList[0] && cartList[0].isNotMail == 1">
          <div v-if="$store.state.chooseTakeAddress.city == '深圳市'">
            <div v-if="288 - Number(totalAmount) > 0">
              还差{{ fixed(288 - Number(totalAmount)) }}元包邮
            </div>
            <div v-else>已达到包邮金额</div>
          </div>
          <div v-else>
            <div v-if="499 - Number(totalAmount) > 0">
              还差{{ fixed(499 - Number(totalAmount)) }}元包邮
            </div>
            <div v-else>已达到包邮金额</div>
          </div>
        </div> -->
        <div>
          <div v-if="Number(freeShippingPrice) - Number(totalAmount) > 0">
            还差{{
              fixed(Number(freeShippingPrice) - Number(totalAmount))
            }}元包邮
          </div>
          <div v-else>已达到包邮金额</div>
        </div>
        <div class="orange">¥ {{ expressFee }}</div>
      </div>
    </titleModule>

    <titleModule :title="'时效&运费说明'" class="maTo20">
      <div style="line-height: 1.5em">
        【洗护时效】<br />
        1、常规洗涤周期：普通衣物订单3-5天左右，含鞋子订单5-7天左右，难处理污渍/特殊问题的订单延时1-2天左右。<br />
        2、旺季洗衣周期：普通衣物订单5-7天左右，含鞋子订单7-10天左右，难处理污渍/特殊问题的订单延时1-2天左右。备注：洗涤周期按订单中涉及最高时长为准，暂无付费加急业务。<br />
        【洗衣运费说明】<br />
        1.1、深圳地区洗衣满58包邮，未满58需补10元邮费，<br />
        1.2、广东省内洗衣满68包邮，未满68需补15元邮费，<br />
        1.3、华北、华南区等等域满78包邮，未满78需补20元邮费，<br />
        1.4、长春、吉林、沈阳、大连等满98包邮，未满98需补30元邮费，<br />
        1.5、呼和浩特、包头、哈尔滨、乌鲁木齐、兰州、西宁、银川、拉萨满158包邮，未满158需补40元邮费。<br />
        2、因个人原因造成快递退回或订单取消，需要您承担来回运费；<br />
        3、因个人原因造成订单部分衣物不洗，不洗衣物金额大于等于订单金额50％，需要您承担运费；<br />
        4、因工厂原因无法清洗，则无需您承担运费；<br />
        5、如大型地毯、大型公仔、大件窗帘等超过包邮价的部分是需要客户自己支付费用。<br />
        【洗护用品运费说明及时效】<br />
        1、全国满99包邮，未满99元补40邮费；<br />
        2、订单下单后即已生成发货需求，需取消订单请联系客服。<br />
        建议您在收到产品后立即检查包裹是否完好无损，并在48小时内联系客服。如果您的包裹在运输过程中受到损害，请立即与我们客服。我们将及时为您处理，并为您提供解决方案，以确保您的利益得到最大程度的保障。收货后48小时内未与我们联系，我们将不承担任何责任。
      </div>
    </titleModule>

    <titleModule :title="'洗护说明'" class="maTo20">
      <div style="line-height: 1.5em">
        1、关于包装：请简单包装衣物避免二次污染(入厂默认会丢弃，请勿使用高档材料)，送回时美家洁会有专用包装箱。<br />
        2、美家洁严格筛选各个地区高品质的中央洗衣工厂，采用顺丰到家取送，通过专业信息化系统，监控21道洗护流程，全流程可追溯，敬请放心；<br />
        3、工厂师傅用心洗护每一件衣物，在保障衣物质量的前提下尽力去除所有污渍，如仍未彻底去除，会放入标记污渍位置的蓝色美家洁沟通卡，敬请谅解；<br />
        4、易褶皱衣物：如西装，短袖等由于包装运输过程可能会有轻微褶皱，收到后请及时挂起，望理解。<br />
        5、自您收到衣物次日算起的48小时内，遇到没洗干净/漏发等问题请勿担心，请扫描包装箱上的售后二维码或者联系美家洁在线客服，我们将第一时间为您解决。<br />
      </div>
    </titleModule>

    <titleModule :title="'特别说明'" class="maTo20" style="margin-bottom: 3rem">
      <div style="line-height: 1.5em">
        特殊时期温馨提醒：出门戴口罩，回家勤洗手，安全你我他！<br />
        【无接触取送】：取送上门前电话沟通，约定指定地点小哥距您1.5米放置包裹<br />
        【衣物灭菌】：专业环保消毒剂，外加定时高温烘干消毒，呵护您的爱衣<br />
        【密封包装】：合格衣物经过自动塑封机一次性无菌防尘袋包装，再装入一次性定制纸箱中胶带密封<br />
      </div>
    </titleModule>

    <!-- orderView -->
    <div>
      <div class="cartDiv" ref="cartDiv">
        <div
          class="centerDiv pflex"
          style="justify-content: space-between; padding-bottom: 0.25rem"
        >
          <div>
            <div>
              <span>快递费：</span>
              <span class="orange" v-if="!$store.state.userInfo.isSteward"
                >¥ {{ fixed(expressFee) }}</span
              >
              <span class="orange" v-else>¥ 0.00</span>
            </div>
            <div class="miniText maTo20">
              <span>优惠</span>
              <span class="orange">{{ fixed(preferentialAmount) }}元</span>
            </div>
          </div>
          <div class="pflex">
            <div
              class="orange"
              style="margin-right: 0.25rem; font-size: 0.35rem"
            >
              <!-- <span v-if="parseFloat(prepaidAmount) + parseFloat(balance) > 0"
                >¥
                {{
                  fixed(parseFloat(prepaidAmount) + parseFloat(balance))
                }}</span
              >
              <span v-else>¥ 0</span> -->
              <span>¥ {{ fixed(totalAmount) }}</span>
            </div>
            <div>
              <div class="orderButton mainBgc" @click.stop="order">去下单</div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <couponChoose
      :showCouponChoose="showCouponChoose"
      :couponList="couponList"
      :totalAmount="totalAmount"
      @closeChoose="closeCouponChoose"
    ></couponChoose>

    <!-- 下单确认弹窗 -->
    <van-popup v-model="ordering" position="bottom">
      <div class="centerDiv cflex">
        <div style="padding: 0.2rem 0; border-bottom: solid #f4f4f4 1px">
          支付确认
        </div>
        <div style="margin-top: 0.6rem">
          <span>订单总额</span>
          <span class="orange" style="margin-left: 0.2rem; font-size: 0.6rem">{{
            fixed(totalAmount + expressFee)
          }}</span>
        </div>
        <div class="maTo20">（含运费{{ fixed(expressFee) }}元）</div>
      </div>
      <div
        style="
          width: 100%;
          background-color: #f4f4f4;
          padding: 0.2rem 0;
          margin-top: 0.5rem;
        "
      >
        <div class="centerDiv">支付方式</div>
      </div>
      <div
        class="centerDiv flex aliCen bt"
        style="border-bottom: solid #f4f4f4 1px; padding: 0.3rem 0"
      >
        <div>
          <div class="bold">企业额度支付</div>
          <div class="miniText orange maTo20">
            当前余额 {{ fixed(zhongxinBalance) }} 元
          </div>
        </div>
        <div>
          <van-icon name="success" color="#1aad19" />
        </div>
      </div>

      <div style="margin-top: 0.5rem" class="centerDiv">
        <van-button
          type="primary"
          color="#1aad19"
          block
          @click="toOrder('mjj/order.json')"
          >确认支付</van-button
        >
        <div class="maTo20 miniText" style="margin-bottom: 1rem">
          <!-- 当余额不足时，剩余部分需要您微信支付哦 -->
        </div>
      </div>
    </van-popup>

    <van-popup v-model="scanning" position="bottom" :style="{ height: '100%' }">
      <div class="page-scan">
        <!-- 扫码区域 -->
        <div class="QrCode">
          <video
            ref="video"
            height="100%"
            width="100%"
            id="video"
            autoplay
          ></video>
        </div>
        <!-- 扫码样式一 -->
        <div class="Qr_scanner">
          <div class="box">
            <div
              style="text-align: center; margin-bottom: 0.5rem; color: #ffffff"
            >
              请靠近并扫描包裹条形码
            </div>
            <div class="line_row">
              <div class="line"></div>
            </div>
            <div class="angle"></div>
          </div>
        </div>
      </div>
      <div
        style="position: fixed; bottom: 1rem; width: 100%; z-index: 99"
        class="pflex"
      >
        <van-button
          type="primary"
          size="small"
          round
          color="#1aad19"
          @click="clickIndexLeft"
          >关闭扫描窗口</van-button
        >
      </div>
    </van-popup>
  </div>
</template>

<script>
import orderAddress from "./orderChooseAddress";
import timeDrawer from "./timeDrawer";
import titleModule from "@/components/titleModule";
import couponChoose from "./couponChoose";

import "webrtc-adapter";
import { BrowserMultiFormatReader, DecodeHintType } from "@zxing/library";

export default {
  data() {
    return {
      scanning: false,
      timeSelect: {},
      remark: "",
      cartHeight: 0,
      addressList: [],
      cartList: [],
      totalAmount: 0.0, // 总价格
      preferentialAmount: 0, // 优惠金额
      expense: 0, // 快递费
      freeShippingPrice: 0, // 包邮金额
      goodsAmount: 0,
      balance: 0,
      prepaidAmount: 0,
      code: "",
      couponList: [],
      useCouponList: [],
      showCouponChoose: false,
      scrollTop: 0,
      couponAmount: 0,
      haveSupplementGood: false,
      canotBeynond: false, // 是否跨省
      canBeyondProvince: [""], // 跨省组合
      update: false,
      packageCode: "",
      codeReader: null,
      ordering: false,
      zhongxinBalance: 0,
    };
  },
  beforeRouteEnter(to, from, next) {
    console.log(to);
    console.log(from);
    next((vm) => {
      if (from.path == "/") {
        vm.update = true;
      } else {
        vm.update = false;
      }
    });
  },
  activated() {
    const that = this;
    if (that.update) {
      that.timeSelect = {};
      that.clickIndexLeft();
      that.packageCode = "";
      that.ordering = false;
      // that.$refs.timeChoose.reset();
    }
    that.cartHeight = this.$refs.cart;
    that.http
      .get("address/list.json", {
        token: that.$store.state.token,
      })
      .then((res) => {
        that.addressList = res.data.addressVOList;
        that.http
          .get("cart/list.json", {
            token: that.$store.state.token,
          })
          .then((res) => {
            that.cartList = res.data.cartVOList;
            that.balance = that.$store.state.userInfo.balance;
            if (that.cartList.length == 0) {
              that.toPage("index");
            } else {
              if (that.cartList[0].isNotMail == 1) {
                that.timeSelect = { day: "今天", time: "14:00~16:00" };
              } else {
                if (that.update) {
                  that.$nextTick(() => {
                    that.$refs.timeChoose.reset();
                  });
                }
              }
              let showPopup = false;
              that.goodsAmount = 0;
              that.totalAmount = 0;
              that.preferentialAmount = 0;
              that.prepaidAmount = 0;
              // that.expense = 0;
              for (let i of that.cartList) {
                if (i.id == that.envConfig().supplementGoodId) {
                  showPopup = true;
                }

                that.goodsAmount = that.goodsAmount + i.goodsNum * i.vipPrice;
                that.goodsAmount.toFixed(2);
                that.totalAmount = that.totalAmount + i.goodsNum * i.vipPrice;
                that.totalAmount.toFixed(2);
                that.preferentialAmount =
                  that.preferentialAmount + (i.price - i.vipPrice) * i.goodsNum;
                that.preferentialAmount.toFixed(2);
              }
              that.prepaidAmount = that.totalAmount - that.balance;
              if (showPopup) {
                that.expense = 0;
                that.haveSupplementGood = true;
                that.$dialog.alert({
                  title: "温馨提示",
                  message: "您的购物车中内含【补价专拍】商品，不会产生物流哦",
                  confirmButtonColor: that.common.mainColor,
                });
              }
            }
          });
      });
  },
  // beforeRouteLeave() {
  //   this.clickIndexLeft();
  // },
  methods: {
    formatter(value) {
      return value.replace(/\s/g, "");
    },
    async openScan() {
      this.codeReader
        .listVideoInputDevices()
        .then((videoInputDevices) => {
          // 默认获取第一个摄像头设备id
          let firstDeviceId = videoInputDevices[0].deviceId;
          // 获取第一个摄像头设备的名称
          const videoInputDeviceslablestr = JSON.stringify(
            videoInputDevices[0].label
          );
          if (videoInputDevices.length > 1) {
            // 判断是否后置摄像头
            if (videoInputDeviceslablestr.indexOf("back") > -1) {
              firstDeviceId = videoInputDevices[0].deviceId;
            } else {
              firstDeviceId = videoInputDevices[1].deviceId;
            }
          }
          // this.codeReader && this.codeReader.reset(); // 重置
          this.decodeFromInputVideoFunc(firstDeviceId);
        })
        .catch((err) => {
          console.error(err);
        });
    },
    decodeFromInputVideoFunc(firstDeviceId) {
      this.codeReader.decodeFromInputVideoDeviceContinuously(
        firstDeviceId,
        "video",
        (result, err) => {
          if (result) {
            this.clickIndexLeft();
            this.$dialog
              .confirm({
                title: "扫描结果",
                message: result.text,
                confirmButtonColor: this.common.mainColor,
                confirmButtonText: "确定包裹",
                cancelButtonText: "重新扫描",
              })
              .then(() => {
                // on confirm
                this.packageCode = result.text;
              })
              .catch(() => {
                // on cancel
                this.startScanning();
              });
          }
          if (err && !err) {
            console.error(err);
          }
        }
      );
    },
    // 停止扫描，并返回上一页
    clickIndexLeft() {
      this.codeReader && this.codeReader.reset();
      this.codeReader = null;
      this.scanning = false;
      document.getElementById("navbar").style.display = "block";
    },
    onBarcodeDetected(result) {
      console.log(result);
      alert(result.codeResult.code);
      this.stopScanning();
      this.scanning = false;
    },
    startScanning() {
      this.scanning = true;
      const hints = new Map();
      hints.set(DecodeHintType.PURE_BARCODE, true);
      hints.set(DecodeHintType.FAST_CODE_READ, true);
      hints.set(DecodeHintType.IMAGE_SCALING, 2);
      this.codeReader = new BrowserMultiFormatReader(hints);
      // this.codeReader.hints = new Map();

      // this.codeReader.hints.set("DecodeHintType.TRY_HARDER", true);
      // this.codeReader.hints.set("DecodeHintType.PURE_BARCODE", true);
      this.openScan();
      document.getElementById("navbar").style.display = "none";
    },
    fixed(num) {
      let res = 0;
      if (typeof num == "number") {
        res = num.toFixed(2);
      }
      return res;
    },
    closeCouponChoose() {
      this.showCouponChoose = false;
      this.amountCom();
    },
    toChoose() {
      this.showCouponChoose = true;
    },
    maxCouponGroup() {
      const that = this;
      if (that.couponList.length > 0) {
        let reuseCouponList = []; // 可叠加优惠券数组
        let cannotReuseCouponList = []; // 不可叠加优惠券数组
        let exReuse = {}; // 最佳可叠加券组合方式
        let exnotReuse = {}; // 最佳不可叠加券组合方式
        for (let i of that.couponList) {
          if (i.limitAmount <= that.totalAmount) {
            if (i.isReuse == 1) {
              reuseCouponList.push(i);
            } else {
              cannotReuseCouponList.push(i);
            }
          }
        }
        // 如果可叠加券存在，计算其最优解
        if (reuseCouponList.length > 0) {
          let canReuseprice = [];
          for (var a2 = []; a2.push([]) < reuseCouponList.length; );
          var l = Math.pow(2, reuseCouponList.length) - 1;
          for (var i = 1; i <= l; i++) {
            var t = [];
            for (var s = i, k = 0; s > 0; s >>= 1, k++)
              if (s & (1 == 1)) t.push(reuseCouponList[k]);
            a2[t.length - 1].push(t);
          }
          for (let i of a2) {
            for (let j of i) {
              let jprice = 0;
              for (let k of j) {
                jprice += k.amount;
                // canReuseprice.push(orderPrice-jprice)
              }
              canReuseprice.push({
                method: j,
                price: Math.abs(that.totalAmount - jprice),
                couponAmountSum: jprice,
              }); // 所有的组合方式及与商品价格差的绝对值
            }
          }
          canReuseprice.sort(function (a, b) {
            return a.price - b.price;
          });
          exReuse = canReuseprice[0]; // 最佳的可复用红包
        }
        // 如果存在不可叠加券，计算最优解
        if (cannotReuseCouponList.length > 0) {
          let cannotReuseprice = [];
          for (let j of cannotReuseCouponList) {
            let jprice = Math.abs(that.totalAmount - j.amount);
            cannotReuseprice.push({
              method: j,
              price: jprice,
              couponAmountSum: j.amount,
            });
            cannotReuseprice.sort(function (a, b) {
              return a.price - b.price;
            });
          }
          exnotReuse = cannotReuseprice[0]; // 最佳的不可复用红包
        }
        // 最终判断（根据可叠加券和不可叠加券的最优解作出综合判断）
        if (cannotReuseCouponList.length > 0 && reuseCouponList.length > 0) {
          if (exnotReuse.price >= exReuse.price) {
            // 选择可叠加券组合
            that.useCouponList = exReuse.method;
          } else {
            // 选择最优不可叠加券
            that.useCouponList = [exnotReuse.method];
          }
        } else {
          // 只有可叠加券
          if (cannotReuseCouponList.length == 0 && reuseCouponList.length > 0) {
            that.useCouponList = exReuse.method;
          }
          // 只有不可叠加券
          if (cannotReuseCouponList.length > 0 && reuseCouponList.length == 0) {
            that.useCouponList = [exnotReuse.method];
          }
        }
        that.$store.commit("useCouponListUpdate", that.useCouponList);
        that.amountCom();
        this.$toast("已为您选好红包最佳搭配");
      } else {
        that.$store.commit("useCouponListUpdate", []);
      }
    },
    getTime(chooseTime) {
      this.timeSelect = chooseTime;
    },
    judgeOrder() {
      const that = this;
      let bool = false;
      if (that.haveSupplementGood) {
        let haveOther = false;
        for (let i of that.cartList) {
          if (i.id != that.envConfig().supplementGoodId) {
            haveOther = true;
            break;
          }
        }
        if (haveOther) {
          that.$dialog.alert({
            title: "温馨提示",
            message: "【补价商品】和其他商品不可以同时下单哦",
            confirmButtonColor: that.common.mainColor,
          });
          bool = false;
        } else {
          bool = true;
        }
      } else {
        bool = true;
      }
      return bool;
    },
    // 判断奢护和普洗
    judgeLuxury() {
      const that = this;
      let isSame = true;
      let isLuxury = that.cartList[0].isLuxury;
      for (let i of that.cartList) {
        console.log(i);
        if (i.isLuxury !== isLuxury) {
          isSame = false;
          break;
        }
      }
      console.log(isSame);
      return isSame;
    },
    // 判断是否跨省
    getBeynond(takeAddressVO, sendAddressVO) {
      const that = this;
      let canBeyond = false;
      let canBeyondProvince = that.canBeyondProvince;
      if (takeAddressVO.province == sendAddressVO.province) {
        canBeyond = true;
      } else {
        for (let j of canBeyondProvince) {
          if (
            j.indexOf(sendAddressVO.province) !== -1 &&
            j.indexOf(takeAddressVO.province) !== -1
          ) {
            canBeyond = true;
            break;
          } else {
            canBeyond = false;
          }
        }
      }
      return canBeyond;
    },
    toOrder(url) {
      const that = this;
      that.http
        .post(url, {
          takeAddressVO: that.$store.state.chooseTakeAddress,
          sendAddressVO: that.$store.state.chooseSendAddress,
          token: that.$store.state.token,
          orderGoodsVOList: that.cartList,
          expressFee: that.expressFee,
          actualAmount: that.actualAmount,
          goodsAmount: that.goodsAmount,
          prepaidAmount: that.prepaidAmount,
          takeDate: that.timeSelect.day + "," + that.timeSelect.time,
          remark: that.remark,
          platform: "wash",
          couponIds: "",
          orderSource: "meijiajieqiye",
          packageCode: that.packageCode,
        })
        .then((res) => {
          if (res.data.stateVO.code == 200) {
            // that.http
            //   .post("mjj/prePay.json", {
            //     token: that.$store.state.token,
            //     orderId: res.data.orderVO.id,
            //     type: "order",
            //     payMethod: "MWEB",
            //   })
            //   .then((payRes) => {
            //     if (payRes.data.stateVO.code == 200) {
            //       let url =
            //         payRes.data.prePayVO.mwebUrl +
            //         `&redirect_url=${encodeURIComponent(
            //           "https://mjj.dajingxiapp.com/#/orderList"
            //         )}`;
            //       window.location = url;
            //     }
            //   });

            if (that.cartList[0].isNotMail == 1) {
              that.redPage("orderList", { tabActive: 1 });
            } else {
              that.redPage("orderList");
            }
          }
        });
    },
    order() {
      const that = this;
      function toOrder() {
        if (that.timeSelect.day) {
          if (
            !that.$store.state.chooseTakeAddress.city ||
            that.addressList.length == 0
          ) {
            that.$dialog.alert({
              title: "提示",
              message: "请选择地址",
              confirmButtonColor: that.common.mainColor,
            });
          } else {
            let couponIds = "";
            let couponAmount = 0;
            if (that.$store.state.useCouponList.length > 0) {
              for (let i of that.$store.state.useCouponList) {
                couponIds = couponIds + "," + i.id;
                couponAmount = couponAmount + i.amount;
              }
              couponAmount = couponAmount.toFixed(2);
              that.couponAmount = couponAmount;
              that.actualAmount = that.actualAmount - couponAmount;
              couponIds = couponIds.substr(1);
            }
            if (
              that.getBeynond(
                that.$store.state.chooseTakeAddress,
                that.$store.state.chooseSendAddress
              )
            ) {
              // let url = "mjj/order.json";
              if (that.$store.state.userInfo.isSteward) {
                // url = "";
                that.toOrder("mjj/shopOrder.json");
              } else {
                that.ordering = true;
              }
            } else {
              that.$dialog.alert({
                title: "温馨提示",
                message:
                  "客户您好，订单省内包邮，如需跨省送回，请下单后联系【在线人工客服】修改地址并支付差额运费。",
                confirmButtonColor: that.common.mainColor,
              });
            }
          }
        } else {
          that.$dialog.alert({
            title: "提示",
            message: "请选择时间",
            confirmButtonColor: that.common.mainColor,
          });
        }
      }
      let isHaveMany = false;
      for (let i of that.cartList) {
        if (i.isNotMail !== that.cartList[0].isNotMail) {
          isHaveMany = true;
          break;
        }
      }
      if (!isHaveMany) {
        if (that.judgeLuxury()) {
          if (that.judgeOrder()) {
            if (that.haveSupplementGood) {
              that.http
                .get("order/list.json", {
                  token: that.$store.state.token,
                  state: 1,
                })
                .then((res) => {
                  if (res.data.orderVOList && res.data.orderVOList.length > 0) {
                    let orderList = res.data.orderVOList;
                    let haveOrder = true;
                    for (let i of orderList) {
                      if (i.status == -1 && i.status >= 5) {
                        haveOrder = false;
                        break;
                      }
                    }
                    if (!haveOrder) {
                      that.$dialog.alert({
                        title: "温馨提示",
                        message: "您没有需要补价的订单哦",
                        confirmButtonColor: that.common.mainColor,
                      });
                    } else {
                      if (that.$store.state.userInfo.isSteward) {
                        if (that.packageCode) {
                          if (that.packageCode.length < 15) {
                            that.$toast("包裹码不足15位");
                          } else {
                            toOrder();
                          }
                        } else {
                          that.$toast("未扫描包裹码");
                        }
                      } else {
                        toOrder();
                        // that.ordering = true;
                      }
                    }
                  } else {
                    that.$dialog.alert({
                      title: "温馨提示",
                      message: "您没有需要补价的订单哦",
                      confirmButtonColor: that.common.mainColor,
                    });
                  }
                });
            } else {
              if (that.$store.state.userInfo.isSteward) {
                if (that.packageCode) {
                  if (that.packageCode.length < 15) {
                    that.$toast("包裹码不足15位");
                  } else {
                    toOrder();
                  }
                } else {
                  that.$toast("未扫描包裹码");
                }
              } else {
                toOrder();
                // that.ordering = true;
              }
            }
          }
        } else {
          that.$dialog.alert({
            title: "温馨提示",
            message: "奢护商品不能和普洗商品同时下单",
            confirmButtonColor: that.common.mainColor,
          });
        }
      } else {
        that.$dialog.alert({
          title: "温馨提示",
          message: "洗衣不能和小商品同时下单",
          confirmButtonColor: that.common.mainColor,
        });
      }
    },
    amountCom() {
      const that = this;
      let couponAmount = 0;
      for (let i of that.$store.state.useCouponList) {
        couponAmount = couponAmount + i.amount;
      }
      couponAmount = couponAmount.toFixed(2);
      that.couponAmount = couponAmount;
      that.prepaidAmount = that.totalAmount - that.balance - couponAmount;
      that.prepaidAmount = that.prepaidAmount.toFixed(2);
    },
    //禁止滚动
    stopScroll() {
      let _scrollTop = document.scrollingElement.scrollTop;
      this.scrollTop = _scrollTop;
      document.body.classList.add("dialog");
      document.body.style.top = -_scrollTop + "px";
      console.log(this.scrollTop);
    },
    // 取消滑动限制
    canScroll() {
      document.body.classList.remove("dialog");
      console.log(this.scrollTop);
      document.scrollingElement.scrollTop = this.scrollTop;
    },
    toRange() {
      this.$router.push({
        name: "iframe",
        query: {
          webSrc: "service_area_zhonghang",
        },
      });
    },
    async getExpressPrice(address) {
      try {
        console.log("address", address);
        // let address= this.$store.state.chooseTakeAddress.city
        const response = await this.http.get("mjj/getExpressPrice.json", {
          cityName: address.city,
          provinceName: address.province,
          isSmallOrder: this.cartList[0] ? this.cartList[0].isNotMail : 0,
        });
        const data = await response.data;
        if (data.stateVO.code == 200) {
          this.expense = data.price;
          this.freeShippingPrice = data.freeShippingPrice;
        }
      } catch (error) {
        console.error(error);
      }
    },
  },
  components: {
    orderAddress,
    timeDrawer,
    titleModule,
    couponChoose,
  },
  computed: {
    expressFee() {
      let expressFee = 0;
      // if (this.cartList[0] && this.cartList[0].isNotMail == 1) {
      //   if (this.$store.state.chooseTakeAddress.city == "深圳市") {
      //     if (this.totalAmount < 288) {
      //       expressFee = 45;
      //     }
      //   } else {
      //     // expressFee = (Number(this.totalAmount) * 0.15).toFixed(2);
      //     if (this.totalAmount < 499) {
      //       expressFee = 85;
      //     }
      //   }
      // } else {
      if (Number(this.totalAmount) < Number(this.freeShippingPrice)) {
        expressFee = Number(this.expense);
      }
      // }
      return expressFee;
    },
  },
  watch: {
    ordering(val) {
      const that = this;
      if (val) {
        if (!that.$store.state.userInfo.isSteward) {
          that.http
            .get("mjj/login.json", {
              token: that.$store.state.token,
            })
            .then((res) => {
              if (res.data.memberVO.token) {
                that.zhongxinBalance = res.data.memberVO.balance;
              }
            });
        }
      }
    },
    scanning(val) {
      if (!val) {
        this.clickIndexLeft();
      }
    },
    "$store.state.chooseTakeAddress": {
      handler(val) {
        if (val.city) {
          this.getExpressPrice(val);
        }
      },
      immediate: true,
      deep: true,
    },
    cartList: {
      handler() {
        if (this.$store.state.chooseTakeAddress.city) {
          this.getExpressPrice(this.$store.state.chooseTakeAddress);
        }
        // this.getExpressPrice();
      },
      immediate: true,
      deep: true,
    },
    // "$store.state.chooseTakeAddress"(val) {
    //   this.http
    //     .get("mjj/getExpressPrice.json", {
    //       cityName: val.city,
    //       provinceName: val.province,
    //       isSmallOrder: this.cartList[0] ? this.cartList[0].isNotMail : 0,
    //     })
    //     .then((res) => {
    //       if (res.data.stateVO.code == 200) {
    //         this.expense = res.data.price;
    //         this.freeShippingPrice = res.data.freeShippingPrice;
    //       }
    //     });
    // },
    // cartList(val) {
    //   this.http
    //     .get("mjj/getExpressPrice.json", {
    //       cityName: this.$store.state.chooseTakeAddress.city,
    //       provinceName: this.$store.state.chooseTakeAddress.province,
    //       isSmallOrder: val[0].isNotMail || 0,
    //     })
    //     .then((res) => {
    //       if (res.data.stateVO.code == 200) {
    //         this.expense = res.data.price;
    //         this.freeShippingPrice = res.data.freeShippingPrice;
    //       }
    //     });
    // },
  },
};
</script>

<style scoped>
.leftImg {
  width: 20%;
}

.leftImg > img {
  width: 75%;
}

.middleInfo {
  width: 60%;
  align-items: flex-start;
  padding-left: 0.2rem;
}
.rightNum {
  width: 20%;
}

.cartGoodsDiv {
  padding: 0.15rem 0;
}

.orderButton {
  color: #ffffff;
  padding: 0.22rem 0.3rem;
  border-radius: 0.1rem;
}

.cartDiv {
  width: 100%;
  background: #ffffff;
  padding: 0.2rem 0;
  padding-bottom: env(safe-area-inset-bottom);
  border-top: #ebe6e6 solid 0.02rem;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
}

.couponDiv {
  width: 100%;
  background: white;
  padding: 0.4rem 0;
  /* border:#e9e9e9 solid 0.01rem */
  box-shadow: 0px 0.05rem 0.13rem #d8d8d8;
}

.QrCode {
  width: 100vw;
  height: 100vh;
  position: relative;
}

#video {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.Qr_scanner {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 9;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
}

.box {
  width: 100vw;
  height: 80vw;
  max-height: 75vh;
  max-width: 75vh;
  position: relative;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  /* border: 1px solid #1aad19; */
}

.line_row {
  width: 100%;
  overflow: hidden;
  background-size: 3rem 3rem;
  background-position: -1rem -1rem;
  animation: Heightchange 2s infinite;
  animation-timing-function: cubic-bezier(0.53, 0, 0.43, 0.99);
  animation-delay: 1.4s;
  border-bottom: 1px solid rgba(140, 255, 136, 0.1);
  display: flex;
  justify-content: center;
  align-items: flex-end;
}

.Qr_scanner .line {
  width: 100%;
  height: 13px;
  background: #1aad19;
  filter: blur(20px);
}
@keyframes radar-beam {
  0% {
    transform: translateY(-100%);
  }
  100% {
    transform: translateY(0);
  }
}
@keyframes Heightchange {
  0% {
    height: 0;
  }
  100% {
    height: 100%;
  }
}
</style>
