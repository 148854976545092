<template>
  <iframe
    id="mapPage"
    frameborder="0"
    src="https://apis.map.qq.com/tools/locpicker?search=1&type=1&policy=1&key=UOFBZ-WN3K6-GJVSJ-MJQQM-6ZIKE-OYB4C&referer=myapp"
  >
  </iframe>
</template>
 
<script>
// import QQMapWX from "@/utils/qqmap-wx-jssdk.min.js";
export default {
  mounted() {
    const that = this;
    window.addEventListener(
      "message",
      function (event) {
        // 接收位置信息，用户选择确认位置点后选点组件会触发该事件，回传用户的位置信息
        var loc = event.data;
        if (loc && loc.module == "locationPicker") {
          //防止其他应用也会向该页面post信息，需判断module是否为'locationPicker'
          // console.log("location", loc);
          if (loc.poiname == "我的位置") {
            that
              .$jsonp(
                `https://apis.map.qq.com/ws/geocoder/v1/?output=jsonp&key=UOFBZ-WN3K6-GJVSJ-MJQQM-6ZIKE-OYB4C&location=${loc.latlng.lat},${loc.latlng.lng}`
              )
              .then((res) => {
                let locObj = {
                  cityname: res.result.ad_info.city,
                  latlng: res.result.ad_info.location,
                  poiaddress: res.result.address,
                  poiname: res.result.address_component.street,
                };
                that.getLocation(locObj);
              })
              .catch(() => {
                that.$toast("解析错误，请选择其他地址");
              });
          } else {
            that.getLocation(loc);
          }
        }
      },
      false
    );
  },
  methods: {
    getLocation(loc) {
      const that = this;
      let reg = /.+?(省|市|自治区|自治州|县|区)/g;
      //   console.log(loc.poiaddress.match(reg));
      let addressArray = loc.poiaddress.match(reg);
      console.log(addressArray);
      let addressInfo = {};
      if (addressArray.length == 2) {
        addressInfo.province = addressArray[0];
        addressInfo.city = addressArray[0];
        addressInfo.district = addressArray[1];
      }
      if (addressArray.length == 3) {
        addressInfo.province = addressArray[0];
        addressInfo.city = addressArray[1];
        addressInfo.district = addressArray[2];
      }
      addressInfo.latitude = loc.latlng.lat;
      addressInfo.longitude = loc.latlng.lng;
      addressInfo.building =
        loc.poiaddress.slice((addressInfo.province + addressInfo.city).length) +
        loc.poiname;
      that.$store.commit("chooseMapUpdate", addressInfo);
      // that.$router.go(-1);
    },
  },
};
</script>

<style scoped>
iframe {
  width: 100vw;
  height: 100vh;
}
</style>

