<template>
  <div class="content">
    <!-- 头部 -->
    <div class="head">
      <div class="centerDiv">
        <div
          style="
            color: #fffeff;
            font-size: 0.4rem;
            font-weight: bold;
            margin-top: 0.1rem;
          "
        >
          洗护售后服务单
        </div>
        <div style="color: #fffeff; line-height: 1.5em" class="maTo30">
          遇到问题请勿担心，我们的客户体验专员会在工作时间尽快联系您。
        </div>
      </div>
    </div>

    <div class="module pflex">
      <div class="pflex mainColor">提交成功 !</div>
      <!-- <div class="mainColor" style="margin-left: 0.3rem" @click="back">
        返回
      </div> -->
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      userTel: "", // 用户手机号
    };
  },
  created() {
    this.userTel = this.$route.query.tel;
  },
  methods: {
    back: function () {
      this.$router.push({
        path: "/",
        query: { tel: this.userTel },
      });
    },
  },
};
</script>

<style scoped>
.head {
  background-color: #ED9027;
  flex-direction: column;
  padding: 0.8rem 0;
}

.module {
  width: 92%;
  background-color: #fffeff;
  border-radius: 0.15rem;
  padding: 0.3rem 0;
  margin: 0 auto;
  margin-top: 0.19rem;
}

.title {
  justify-content: space-between;
  color: #525151;
  margin-bottom: 0.22rem;
}

.mainColor {
  color: #ED9027;
}

.mainBgc {
  background: #ED9027;
}

</style>