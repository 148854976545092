import Vue from 'vue'
import Router from 'vue-router'
import Index from '@/views/index/index.vue'
import OrderList from '@/views/orderList/orderList.vue'
import User from '@/views/user/user.vue'
import Login from '@/views/login/login.vue'
import Balance from '@/views/user/balance/balance.vue'
import Coupon from '@/views/user/coupon/coupon.vue'
import Iframe from '@/views/iframe/iframe.vue'
import ChooseCity from '@/views/index/chooseCity/chooseCity'
import Address from '@/views/user/address/address'
import Pay from '@/views/user/pay/pay'
import EditAddress from '@/views/user/address/editAddress/editAddress'
import MapPage from '@/views/user/address/map/map'
import CE from '@/views/ce/ce'
import Evaluate from '@/views/orderList/evaluate/evaluate'
import Order from '@/views/order/order'
import OrderDetail from '@/views/orderList/orderDetail/orderDetail'
import StatusList from '@/views/orderList/statusList/statusList'
import CouponChoose from '@/views/order/couponChoose/couponChoose'
import GroupCoupon from '@/views/user/groupCoupon/groupCoupon'
import CeWechat from '@/views/user/ceWechat/ceWechat'
import AfterSale from '@/views/afterSale/index'
import Complaint from '@/views/afterSale/complaint'
import Result from '@/views/afterSale/result'
import Service from '@/views/afterSale/service'

Vue.use(Router)

const originalPush = Router.prototype.push
Router.prototype.push = function push(location, onResolve, onReject) {
    if (onResolve || onReject) return originalPush.call(this, location, onResolve, onReject)
    return originalPush.call(this, location).catch(err => err)
}

export default new Router({
    mode: 'hash',
    base: process.env.BASE_URL,
    // scrollBehavior() {
    //     return { x: 0, y: 0 };
    // },
    routes: [{
            path: "/",
            name: "index",
            component: Index,
        },
        {
            path: "/user",
            component: User,
            name: "user",
            meta: {
                requireAuth: true,
                keepAlive: false
            }
        },
        {
            path: "/orderList",
            component: OrderList,
            name: "orderList",
            meta: {
                requireAuth: true,
                keepAlive: false
            }
        },
        {
            path: "/login",
            name: "login",
            component: Login,
            meta: {
                cName: "登录",
                keepAlive: false
            }
        },
        {
            path: "/balance",
            name: "balance",
            component: Balance,
            meta: {
                requireAuth: true,
                cName: "明细",
                keepAlive: false
            }
        },
        {
            path: "/coupon",
            name: "coupon",
            component: Coupon,
            meta: {
                requireAuth: true,
                cName: "卡券包",
                keepAlive: false
            }
        },
        {
            path: "/iframe",
            component: Iframe,
            name: "iframe",
            meta: {
                cName: "美家洁"
            }
        }, {
            path: "/chooseCity",
            component: ChooseCity,
            name: "chooseCity"
        },
        {
            path: "/address",
            component: Address,
            name: "address",
            meta: {
                requireAuth: true,
                cName: "我的地址",
                keepAlive: false
            }
        },
        {
            path: "/editAddress",
            component: EditAddress,
            name: "editAddress",
            meta: {
                requireAuth: true,
                cName: "编辑地址",
                keepAlive: false
            }
        },
        {
            path: "/pay",
            component: Pay,
            name: "pay",
            meta: {
                requireAuth: true,
                cName: "订单支付",
                keepAlive: false
            }
        },
        {
            path: "/ce",
            component: CE,
            name: "ce",
            meta: {
                requireAuth: true,
                cName: "美家洁客户服务",
                keepAlive: false
            }
        },
        {
            path: "/evaluate",
            component: Evaluate,
            name: "evaluate",
            meta: {
                requireAuth: true,
                cName: "评价",
                keepAlive: false
            }
        },
        {
            path: "/order",
            component: Order,
            name: "order",
            meta: {
                requireAuth: true,
                cName: "确认订单",
                keepAlive: true
            }
        },
        {
            path: "/orderDetail",
            component: OrderDetail,
            name: "orderDetail",
            meta: {
                requireAuth: true,
                cName: "订单详情",
                keepAlive: true
            }
        },
        {
            path: "/map",
            component: MapPage,
            name: "map",
            meta: {
                requireAuth: true,
                cName: "地图",
                keepAlive: false
            }
        },
        {
            path: "/statusList",
            component: StatusList,
            name: "statusList",
            meta: {
                requireAuth: true,
                cName: "订单进度",
                keepAlive: false
            }
        },
        {
            path: "/couponChoose",
            component: CouponChoose,
            name: "couponChoose",
            meta: {
                requireAuth: true,
                cName: "选择红包",
                keepAlive: false
            }
        },
        {
            path: "/groupCoupon",
            component: GroupCoupon,
            name: "groupCoupon",
            meta: {
                requireAuth: true,
                cName: "红包核销",
                keepAlive: false
            }
        },
        {
            path: "/ceWechat",
            component: CeWechat,
            name: "CeWechat",
            meta: {
                cName: "彩云鲸洗",
                noNav: true,
                noCe: true,
                title: '扫一扫添加'
            }
        },
        {
            path: "/afterSale",
            component: AfterSale,
            name: "afterSale",
            meta: {
                cName: "彩云鲸洗",
                noNav: true,
                noCe: true,
                title: '洗护售后服务单',
                nav: '#ED9027'
            }
        },
        {
            path: "/afterSale/complaint",
            component: Complaint,
            name: "complaint",
            meta: {
                cName: "投诉",
                noCe: true,
                title: '洗护售后服务单',
                nav: '#ED9027'
            }
        },
        {
            path: "/afterSale/result",
            component: Result,
            name: "result",
            meta: {
                cName: "提交结果",
                noCe: true,
                title: '洗护售后服务单',
                nav: '#ED9027'
            }
        },
        {
            path: "/afterSale/service",
            component: Service,
            name: "service",
            meta: {
                cName: "售后",
                noCe: true,
                title: '洗护售后服务单',
                nav: '#ED9027'
            }
        }
    ]
})