<template>
  <div class="content commonText">
    <!-- status -->
    <div style="background: white; padding: 0.3rem 0">
      <div style="width: 100%">
        <van-steps
          :active="orderInfo.status - 1"
          :active-color="common.mainColor"
        >
          <van-step>待付款</van-step>
          <van-step>待取件</van-step>
          <van-step>待入厂</van-step>
          <van-step>处理中</van-step>
          <van-step>待收货</van-step>
          <van-step>待评价</van-step>
        </van-steps>
      </div>
    </div>

    <titleModule :title="'进度信息'" class="maTo20">
      <div
        v-if="orderInfo.orderStateVO"
        @click="toPage('statusList', { orderId: orderInfo.id })"
      >
        <div style="line-height: 1.5em">
          {{ orderInfo.orderStateVO.remark }}
        </div>
        <div class="maTo20">{{ orderInfo.orderStateVO.createTime }}</div>
      </div>
    </titleModule>

    <titleModule :title="'下单地址'" class="maTo20" :slotWidth="'100'">
      <div class="addDiv">
        <div class="centerDiv pflex">
          <div class="left pflex">
            <img src="@/images/addr.png" style="width: 100%" alt="" />
          </div>
          <div class="middle">
            <div class="centerDiv" v-if="orderInfo.takeAddressVO">
              <div style="line-height: 1.5em">
                {{ orderInfo.takeAddressVO.city
                }}{{ orderInfo.takeAddressVO.district
                }}{{ orderInfo.takeAddressVO.building
                }}{{ orderInfo.takeAddressVO.address }}
              </div>
              <div class="maTo20 miniText">
                {{ orderInfo.takeAddressVO.name }}
                {{ orderInfo.takeAddressVO.telphone }}
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- line -->
      <div class="line centerDiv"></div>

      <!-- send -->
      <div class="addDiv">
        <div class="centerDiv pflex">
          <div class="left pflex">
            <img src="@/images/addr-song.png" style="width: 100%" alt="" />
          </div>
          <div class="middle">
            <div class="centerDiv" v-if="orderInfo.sendAddressVO">
              <div style="line-height: 1.5em">
                {{ orderInfo.sendAddressVO.city
                }}{{ orderInfo.sendAddressVO.district
                }}{{ orderInfo.sendAddressVO.building
                }}{{ orderInfo.sendAddressVO.address }}
              </div>
              <div class="maTo20 miniText">
                {{ orderInfo.sendAddressVO.name }}
                {{ orderInfo.sendAddressVO.telphone }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </titleModule>

    <titleModule :title="'订单商品'" class="maTo20" :slotWidth="'100'">
      <div v-if="orderInfo.orderGoodsVOList">
        <div
          v-for="(item, index) in orderInfo.orderGoodsVOList"
          :key="index"
          class="cartGoodsDiv"
          style="border-bottom: #f5f1f1 solid 0.01rem"
        >
          <div class="pflex centerDiv">
            <div class="leftImg pflex">
              <img :src="item.picurl" alt="" />
            </div>
            <div class="middleInfo cflex">
              <div style="line-height: 1.5em">{{ item.name }}</div>
              <div class="orange maTo20">¥{{ item.vipPrice }}</div>
            </div>
            <div class="rightNum">数量 x {{ item.goodsNum }}</div>
          </div>
          <div
            class="maTo20 miniText centerDiv"
            v-if="orderInfo.orderGoodsVOList[index].defectiveRemark"
          >
            <div class="orange" style="line-height: 1.5em">
              工厂反馈：{{ orderInfo.orderGoodsVOList[index].defectiveRemark }}
            </div>
          </div>
        </div>
      </div>
    </titleModule>

    <titleModule :title="'订单信息'" class="maTo20" :slotWidth="'100'">
      <div class="centerDiv miniText">
        <div class="pflex bt">
          <div>订单编号：</div>
          <div>{{ orderInfo.orderNo }}</div>
        </div>
        <div class="pflex bt maTo20">
          <div>下单时间</div>
          <div>{{ orderInfo.createTime }}</div>
        </div>
        <div class="pflex bt maTo20">
          <div>取衣时间</div>
          <div>{{ orderInfo.takeDate }}</div>
        </div>
        <div class="pflex bt maTo20">
          <div>衣服预估件数</div>
          <div>{{ orderInfo.goodsNum }}件</div>
        </div>
        <div class="pflex bt maTo20">
          <div>实际衣物数量</div>
          <div>{{ orderInfo.actualGoodsNum }}件</div>
        </div>
        <div class="pflex bt maTo20">
          <div>商品金额</div>
          <div>¥ {{ orderInfo.goodsAmount }}</div>
        </div>
        <div class="pflex bt maTo20">
          <div>运费</div>
          <div>¥ {{ orderInfo.expressFee }}</div>
        </div>
        <div class="pflex bt maTo20">
          <div>红包</div>
          <div>¥ {{ orderInfo.couponAmount }}</div>
        </div>
        <div class="pflex bt maTo20">
          <div>预付金额</div>
          <div>¥ {{ orderInfo.prepaidAmount }}</div>
        </div>
      </div>
    </titleModule>

    <titleModule :title="'时效&运费说明'" class="maTo20">
      <div style="line-height: 1.5em">
        【洗护时效】<br />
        1、常规洗涤周期：普通衣物订单3-5天左右，含鞋子订单5-7天左右，难处理污渍/特殊问题的订单延时1-2天左右。<br />
        2、旺季洗衣周期：普通衣物订单5-7天左右，含鞋子订单7-10天左右，难处理污渍/特殊问题的订单延时1-2天左右。备注：洗涤周期按订单中涉及最高时长为准，暂无付费加急业务。<br />
        【洗衣运费说明】<br />
        1.1、深圳地区洗衣满58包邮，未满58需补10元邮费，<br />
        1.2、广东省内洗衣满68包邮，未满68需补15元邮费，<br />
        1.3、华北、华南区等等域满78包邮，未满78需补20元邮费，<br />
        1.4、长春、吉林、沈阳、大连等满98包邮，未满98需补30元邮费，<br />
        1.5、呼和浩特、包头、哈尔滨、乌鲁木齐、兰州、西宁、银川、拉萨满158包邮，未满158需补40元邮费。<br />
        2、因个人原因造成快递退回或订单取消，需要您承担来回运费；<br />
        3、因个人原因造成订单部分衣物不洗，不洗衣物金额大于等于订单金额50％，需要您承担运费；<br />
        4、因工厂原因无法清洗，则无需您承担运费；<br />
        5、如大型地毯、大型公仔、大件窗帘等超过包邮价的部分是需要客户自己支付费用。<br />
        【洗护用品运费说明及时效】<br />
        1、全国满99包邮，未满99元补40邮费；<br />
        2、订单下单后即已生成发货需求，需取消订单请联系客服。<br />
        建议您在收到产品后立即检查包裹是否完好无损，并在48小时内联系客服。如果您的包裹在运输过程中受到损害，请立即与我们客服。我们将及时为您处理，并为您提供解决方案，以确保您的利益得到最大程度的保障。收货后48小时内未与我们联系，我们将不承担任何责任。
      </div>
    </titleModule>

    <titleModule :title="'洗护说明'" class="maTo20">
      <div style="line-height: 1.5em">
        1、关于包装：请简单包装衣物避免二次污染(入厂默认会丢弃，请勿使用高档材料)，送回时美家洁会有专用包装箱。<br />
        2、美家洁严格筛选各个地区高品质的中央洗衣工厂，采用顺丰到家取送，通过专业信息化系统，监控21道洗护流程，全流程可追溯，敬请放心；<br />
        3、工厂师傅用心洗护每一件衣物，在保障衣物质量的前提下尽力去除所有污渍，如仍未彻底去除，会放入标记污渍位置的蓝色美家洁沟通卡，敬请谅解；<br />
        4、易褶皱衣物：如西装，短袖等由于包装运输过程可能会有轻微褶皱，收到后请及时挂起，望理解。<br />
        5、自您收到衣物次日算起的48小时内，遇到没洗干净/漏发等问题请勿担心，请扫描包装箱上的售后二维码或者联系美家洁在线客服，我们将第一时间为您解决。<br />
      </div>
    </titleModule>

    <titleModule :title="'特别说明'" class="maTo20" style="margin-bottom: 3rem">
      <div style="line-height: 1.5em">
        特殊时期温馨提醒：出门戴口罩，回家勤洗手，安全你我他！<br />
        【无接触取送】：取送上门前电话沟通，约定指定地点小哥距您1.5米放置包裹<br />
        【衣物灭菌】：专业环保消毒剂，外加定时高温烘干消毒，呵护您的爱衣<br />
        【密封包装】：合格衣物经过自动塑封机一次性无菌防尘袋包装，再装入一次性定制纸箱中胶带密封<br />
      </div>
    </titleModule>

    <div style="width: 100%; margin-bottom: 2rem">
      <div class="centerDiv pflex buttomButton maTo20 ceButton" @click="toCe()">
        联系客服
      </div>
      <div
        class="centerDiv pflex buttomButton maTo20 ceButton"
        v-if="orderInfo.status < 3 && orderInfo.status !== -1"
        @click="cancelOrder"
      >
        取消订单
      </div>
    </div>
    <!-- bottomButtonGroup -->
    <div
      class="bottomButtonGroup"
      v-if="orderInfo.topaidAmount > 0 && orderInfo.status !== -1"
    >
      <div
        class="centerDiv pflex buttomButton mainBgc"
        style="color: white"
        @click="toPay"
      >
        付款 ¥{{ orderInfo.topaidAmount }}
      </div>
    </div>
  </div>
</template>

<script>
import titleModule from "@/components/titleModule";
export default {
  data() {
    return {
      active: 0,
      orderInfo: { status: 1 },
      orderId: "",
      code: "",
      type: 0,
    };
  },
  activated() {
    const that = this;
    if (that.$route.params.orderId) {
      that.orderId = that.$route.params.orderId;
      if (that.$route.params.type) {
        that.type = that.$route.params.type;
      }
    }
    if (that.$route.params.orderId || that.orderId != "") {
      that.http
        .get("order/detail.json", {
          token: that.$store.state.token,
          orderId: that.orderId,
        })
        .then((res) => {
          that.orderInfo = res.data.orderVO;
        });
    } else {
      that.toPage("orderList");
    }
  },
  components: {
    titleModule,
  },
  methods: {
    toCe() {
      window.ysf("open");
    },
    toPay() {
      const that = this;
      that.http
        .get("recharge/prePay.json", {
          token: that.$store.state.token,
          amount: that.orderInfo.topaidAmount,
          orderId: that.orderInfo.id,
          payMethod: "zhpay",
          type: "order",
          zhUserId: that.$store.state.userInfo.zhUserId,
          zhUserType: that.$store.state.userInfo.zhUserType || "",
        })
        .then((payRes) => {
          if (payRes.data.stateVO.code == 200) {
            window.location.href = payRes.data.prePayVO.mwebUrl;
          }
        });
    },
    cancelOrder() {
      const that = this;
      that.$dialog
        .confirm({
          title: "提示",
          message: "是否取消订单,如果已支付，钱款稍后将会退回",
          confirmButtonColor: that.common.mainColor,
        })
        .then(() => {
          // on confirm
          that.http
            .get("mjj/delete.json", {
              token: that.$store.state.token,
              // openId: that.$store.state.openId,
              orderId: that.orderInfo.id,
              status: -1,
            })
            .then(() => {
              if (that.$route.params.orderId || that.orderId != "") {
                that.http
                  .get("order/detail.json", {
                    token: that.$store.state.token,
                    // openId: that.$store.state.openId,
                    orderId: that.orderId,
                  })
                  .then((res) => {
                    that.orderInfo = res.data.orderVO;
                  });
              } else {
                that.toPage("orderList");
              }
            });
        })
        .catch(() => {
          // on cancel
        });
    },
  },
};
</script>

<style scoped>
.addDiv {
  background: white;
  padding: 0.3rem 0;
  width: 100%;
}
.left {
  width: 15%;
}

.middle {
  width: 85%;
}

.right {
  width: 10%;
  justify-content: flex-end;
}

.line {
  background: #e4e4e4;
  height: 0.01rem;
}

.leftImg {
  width: 20%;
}

.leftImg > img {
  width: 75%;
}

.middleInfo {
  width: 60%;
  align-items: flex-start;
  padding-left: 0.2rem;
}
.rightNum {
  width: 20%;
}

.cartGoodsDiv {
  padding: 0.15rem 0;
}

.bottomButtonGroup {
  position: fixed;
  width: 100%;
  bottom: 0;
  left: 0;
  padding: 0.3rem 0;
  background: white;
}

.buttomButton {
  padding: 0.3rem 0;
  border-radius: 0.15rem;
}

.ceButton {
  background: #fff;
  box-shadow: 0px 0px 10px #d6d6d6;
}

.cancel {
  border: solid 1px #a3a3a3;
}
</style>