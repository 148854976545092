<template>
  <div class="content" v-if="normalOperation">
    <!-- 加载动画 -->
    <!-- <div class="loading pflex" v-if="loading">
      <van-loading type="spinner" color="#85d4db" vertical>加载中</van-loading>
    </div> -->
    <!-- <div class="over" v-if="loading"></div> -->
    <!-- 头部 -->
    <div class="head">
      <div class="centerDiv">
        <div
          style="
            color: #fffeff;
            font-size: 0.4rem;
            font-weight: bold;
            margin-top: 0.1rem;
          "
        >
          洗护售后服务单
        </div>
        <div style="color: #fffeff; line-height: 1.5em" class="maTo30">
          遇到问题请勿担心，我们的客户体验专员会在工作时间尽快联系您。
        </div>
      </div>
    </div>
    <!-- 手机号查询 -->
    <keep-alive>
      <div class="module">
        <div class="centerDiv">
          <div class="title pflex">查询订单</div>
          <div class="flex aliCen" style="width: 100%; margin-top: 0.4rem">
            <div style="width: 80%">
              <input
                v-model="telphone"
                type="number"
                placeholder="输入您的手机号"
                pattern="\d*"
              />
              <div class="inputline"></div>
            </div>
            <div
              class="mainColor pflex"
              style="margin-left: 0.1rem; width: 20%"
              @click="query"
            >
              查询
            </div>
          </div>
        </div>
      </div>
    </keep-alive>
    <!-- 订单列表 -->
    <div v-if="orderList.length > 0">
      <div
        class="module"
        v-for="(item, index) in orderList"
        :key="index"
        style="padding: 0.1rem 0"
      >
        <!-- 循环列表 -->
        <div class="order">
          <!-- 单个订单 -->
          <div class="everyOrder">
            <div class="pflex" style="justify-content: space-between">
              <div class="mainColor" style="width: 65%; line-height: 1.5em">
                {{ item.orderNo }}
              </div>
              <div
                class="pflex"
                style="justify-content: space-between; width: 35%"
              >
                <div
                  class="commonText"
                  @click="complaint(item.id, item.orderNo)"
                >
                  投诉
                </div>
                <div class="mainColor" @click="service(item.id, item.orderNo)">
                  申请售后
                </div>
              </div>
            </div>
            <!-- 订单售后信息 -->
            <div class="line" v-if="item.asTicketVOList.length > 0"></div>
            <div
              class="pflex mainColor"
              style="justify-content: flex-start; margin: 0.1rem 0"
              v-if="item.asTicketVOList.length > 0"
            >
              售后信息
            </div>
            <!-- 售后工单列表 -->
            <div
              class="commonText pflex"
              @click="showServe(index, number)"
              style="
                width: 90%;
                margin: 0 auto;
                justify-content: space-between;
                padding: 0.1rem 0;
              "
              v-for="(value, number) in item.asTicketVOList"
              :key="value.id"
            >
              <div>工单 {{ item.id }}</div>
              <div>点击查看</div>
            </div>
            <div class="line" v-if="item.tsTicketVOList.length > 0"></div>
            <div
              class="pflex mainColor"
              style="justify-content: flex-start; margin: 0.1rem 0"
              v-if="item.tsTicketVOList.length > 0"
            >
              投诉信息
            </div>
            <div
              class="commonText pflex"
              @click="showComplaint(index, number)"
              style="
                width: 90%;
                margin: 0 auto;
                justify-content: space-between;
                padding: 0.1rem 0;
              "
              v-for="(value, number) in item.tsTicketVOList"
              :key="value.id"
            >
              <div>工单 {{ item.id }}</div>
              <div>点击查看</div>
            </div>
            <div class="line" v-if="item.tsTicketVOList.length > 0"></div>
          </div>
        </div>
      </div>
    </div>

    <!-- 没有订单的情况 -->
    <!-- <div class="module" v-if="orderList.length==0">
      <div class="title pflex">我的订单</div>
      <div class="commonText pflex">这里什么都没有呢</div>
    </div>-->

    <!-- 温馨提示 -->
    <div class="module">
      <div class="centerDiv">
        <div class="title">温馨提示</div>
        <div class="commonText title">
          1.请注意衣物不要穿着并不要丢弃洗涤标签
        </div>
        <div class="commonText title">
          2.提交服务单后请您保持手机畅通，售后工作人员会在48小时内联系您处理
        </div>
        <div class="commonText title">
          3.请在提供售后照片时，同时提供水洗码照片
        </div>
      </div>
    </div>

    <!-- 售后内容展示 -->
    <van-popup v-model="show" position="bottom" :style="{ height: '70%' }">
      <div style="width: 90%; margin: 0.3rem auto 0 auto">
        <!-- <div class="mainColor">您遇到的问题</div> -->
        <!-- 问题列表循环 -->
        <div class="pflex" style="flex-wrap: wrap; justify-content: flex-start">
          <div
            class="pflex"
            style="width: 50%; justify-content: flex-start; padding: 0.1rem 0"
            v-for="(item, index) in labelList"
            :key="index"
          >
            <div class="commonText" style="margin-left: 0.2rem">{{ item }}</div>
          </div>
        </div>
        <div class="line"></div>
        <div class="serveInfo" v-html="serveInfo"></div>
      </div>
    </van-popup>
    <div style="width: 100%; height: 0.3rem"></div>
  </div>
</template>

<script>
// import { Loading, Toast, Image, Popup } from 'vant'
// import 'vant/lib/loading/style'
// import 'vant/lib/toast/style'
// import 'vant/lib/field/style'
// import 'vant/lib/image/style'
// import 'vant/lib/popup/style'
export default {
  name: "App",
  data() {
    return {
      orderList: [], // 订单列表
      telphone: "", // 输入的手机号
      code: "", // 微信用户标识
      userInfo: {}, // 用户信息
      loading: false, // 是否是加载状态
      isWechat: false, // 微信打开
      isAlipay: false, // 支付宝打开
      isQuery: false, // 是否查询
      show: false,
      serveInfo: "", // 底部售后信息弹窗展示内容
      labelList: [],
      normalOperation: true, // 页面是否正常运行
      userTel: "", // 用户手机号
    };
  },
  created() {
    const that = this;
    window.console.log(that.$route.query.tel);
    const ua = navigator.userAgent.toLowerCase();
    const reg = new RegExp("(^|&)" + "code" + "=([^&]*)(&|$)", "i");
    const r = window.location.search.substr(1).match(reg);
    if (r) {
      this.code = r[2];
      this.loading = true;
      this.http
        .get("recharge/jsapiConfig.json", {
          code: this.code,
          sellId: "",
        })
        .then((res) => {
          this.loading = false;
          // 获取到用户信息
          that.userInfo = res.data;
          that.http
            .get("order/listByTicket.json", {
              openId: that.userInfo.openId,
            })
            .then((res) => {
              if (res.data.stateVO.code == 200) {
                that.orderList = res.data.ticketOrderVOList;
              }
            });
        })
        .catch((err) => {
          window.console.log(err);
          this.loading = false;
        });
    } else {
      if (that.$route.query.tel) {
        that.telphone = that.$route.query.tel;
        that.query();
      }
    }
    // 判断是不是在微信中打开
    if (ua.match(/MicroMessenger/i) == "micromessenger") {
      // window.console.log('微信内打开')
      // that.isWechat = true
      // if (!r) {
      //   that.normalOperation = false
      //   window.location =
      //     'https://open.weixin.qq.com/connect/oauth2/authorize?appid=wx25ae2da748515faf&redirect_uri=' +
      //     encodeURIComponent(window.location.href) +
      //     '&response_type=code&scope=snsapi_base&state=DAJINGXI'
      // }
    } else {
      // 不在微信中打开
    }
  },
  beforeRouteLeave(to, from, next) {
    from.meta.keepAlive = false;
    next();
  },
  mounted() {
    if (window.history && window.history.pushState) {
      // 向历史记录中插入了当前页
      history.pushState(null, null, document.URL);
      window.addEventListener("popstate", this.goBack, false);
    }
  },
  methods: {
    goBack() {
      // console.log("点击了浏览器的返回按钮");
      history.pushState(null, null, document.URL);
    },
    // 根据手机号查询订单
    query: function () {
      const that = this;
      const reg = /^[1][3,4,5,6,7,8,9][0-9]{9}$/;
      if (!reg.test(that.telphone)) {
        // Toast('手机号错误')
        that.$toast("手机号错误");
      } else {
        that.loading = true;
        window.console.log(that.globalUrl);
        that.http
          .get("order/listByTicket.json", {
            telphone: this.telphone,
          })
          .then((res) => {
            that.loading = false;
            if (res.data.stateVO.code == 200) {
              that.orderList = res.data.ticketOrderVOList;
              that.isQuery = true;
              if (res.data.ticketOrderVOList.length == 0) {
                // Toast("没有可以申请售后的订单哦");
                that.$toast("没有可以申请售后的订单哦");
              } else {
                that.userTel = that.telphone;
              }
            } else {
              //   Toast(res.data.stateVO.msg);
              that.$toast(res.data.stateVO.msg);
              that.orderList = [];
            }
          })
          .catch((err) => {
            that.loading = false;
            window.console.log(err);
          });
      }
    },
    // 投诉
    complaint: function (orderId, orderNo) {
      // 如果有用户信息
      const that = this;
      if (that.userInfo.id && that.isQuery == false) {
        if (that.isWechat == true) {
          that.$router.push({
            path: "/afterSale/complaint",
            query: {
              orderId: orderId,
              orderNo: orderNo,
              memberId: that.userInfo.id,
              openId: that.userInfo.openId,
              token: that.userInfo.token,
              tel: that.userTel,
            },
          });
        }
      } else {
        this.$router.push({
          path: "/afterSale/complaint",
          query: { orderId: orderId, orderNo: orderNo, tel: that.userTel },
        });
      }
    },
    // 申请售后
    service: function (orderId, orderNo) {
      const that = this;
      if (that.userInfo.id && that.isQuery == false) {
        if (that.isWechat == true) {
          that.$router.push({
            path: "/afterSale/service",
            query: {
              orderId: orderId,
              orderNo: orderNo,
              memberId: that.userInfo.id,
              openId: that.userInfo.openId,
              token: that.userInfo.token,
              tel: that.userTel,
            },
          });
        }
      } else {
        this.$router.push({
          path: "/afterSale/service",
          query: { orderId: orderId, orderNo: orderNo, tel: that.userTel },
        });
      }
    },
    // 查看售后
    showServe: function (index, number) {
      this.show = !this.show;
      const serveInfoStr = this.orderList[index].asTicketVOList[number].remark;
      const labelListStr =
        this.orderList[index].asTicketVOList[number].ticketLabel;
      this.serveInfo = this.orderList[index].asTicketVOList[number].remark;
      window.console.log(serveInfoStr.split("</p>"));
      this.labelList = labelListStr.split(",");
      window.console.log(number);
    },
    // 查看投诉
    showComplaint: function (index, number) {
      this.show = !this.show;
      const labelListStr =
        this.orderList[index].tsTicketVOList[number].ticketLabel;
      this.serveInfo = this.orderList[index].tsTicketVOList[number].remark;
      this.labelList = labelListStr.split(",");
    },
  },
  components: {
    // [Loading.name]: Loading,
    // [Toast.name]: Toast,
    // [Image.name]: Image,
    // [Popup.name]: Popup,
  },
  // 监听数量变化
  watch: {},
};
</script>

<style  scoped>
body {
  background-color: #f3f4f5;
}

.loading {
  position: fixed;
  font-size: 0.25rem;
  height: 20%;
  width: 80%;
  left: 50%;
  top: 50%;
  display: flex;
  flex-direction: column;
  align-content: center;
  transform: translate(-50%, -50%);
  z-index: 1000;
}

.over {
  position: fixed;
  width: 100%;
  height: 100%;
  opacity: 0.7;
  filter: alpha(opacity=70);
  top: 0;
  left: 0;
  z-index: 999;
  background-color: #111111;
}

.mainColor {
  color: #ED9027;
}

.mainBgc {
  background-color: #ED9027;
}

.pflex {
  display: flex;
  justify-content: center;
  align-items: center;
}

.title {
  justify-content: space-between;
  color: #525151;
  margin-bottom: 0.22rem;
}

.module {
  width: 92%;
  background-color: #fffeff;
  border-radius: 0.15rem;
  padding: 0.3rem 0 0.6rem 0;
  margin: 0 auto;
  margin-top: 0.19rem;
}

.content {
  width: 100%;
  height: 100%;
  overflow: hidden;
  font-size: 0.3rem;
  letter-spacing: 0.01rem;
}

.line {
  width: 95%;
  margin: 0 auto;
  height: 0.02rem;
  background: #f3f4f5;
  margin: 0.1rem 0;
}

.head {
  background-color: #ED9027;
  flex-direction: column;
  padding: 0.8rem 0;
}

.inputline {
  width: 100%;
  background-color: #ED9027;
  height: 1px;
  margin-top: 0.2rem;
}

input {
  color: #a2a2a2;
  width: 100%;
  -webkit-appearance: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  border: none;
  outline: none;
  display: inline-block;
}

input::placeholder {
  color: rgb(209, 209, 209);
}

/* input::-webkit-input-placeholder {
  color: #a2a2a2;
}

input::-moz-placeholder {
  color: #a2a2a2;
}

input:-ms-input-placeholder {
  color: #a2a2a2;
} */

.commonText {
  color: #797979;
  font-size: 0.3rem;
  line-height: 1.5em;
}

.order {
  width: 90%;
  margin: 0 auto;
}

.everyOrder {
  padding: 0.1rem 0;
}

.serveInfo {
  padding: 0 0.2rem;
}

.serveInfo > p {
  color: #a2a2a2;
  font-size: 0.26rem;
  margin: 0.15rem 0;
}

.mainColor {
  color: #ED9027;
}

.mainBgc {
  background: #ED9027;
}

</style>
